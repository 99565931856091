import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import envConfig from "../config";
import { accessTokenHandler, refreshTokenHandler } from "src/utils/cookieHelper";
import { refreshToken } from "src/utils/helper";
import { PostLogApiPayload, RegisterApiPayload } from "src/types/api.type";
import PageRoutes from "src/constants/pageRoutes";

let pendingRequest: any = [];
let isRefreshing = false;

export const apiClient = axios.create({
  baseURL: envConfig.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptors for request
apiClient.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = accessTokenHandler("GET");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        apiClient.defaults.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    if (isRefreshing) {
      return new Promise((res) => {
        subscribeTokenRefresh((token: any) => {
          if (token) {
            config.headers.Authorization = "Bearer " + token;
          }
          res(config);
        });
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function subscribeTokenRefresh(callback: any) {
  pendingRequest.push(callback);
}
function logoutUser() {
  accessTokenHandler("REMOVE");
  refreshTokenHandler("REMOVE");
  window.location.href = PageRoutes.LOGIN;
}

function onRefreshed(token?: string) {
  pendingRequest.forEach((callback: any) => callback(token));
  pendingRequest = [];
}

// Interceptors for response
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const newConfig = error.config;
    if (error.response && error.response?.status === 401 && !newConfig._retry) {
      newConfig._retry = true;
      if (isRefreshing) {
        return new Promise((res, rej) => {
          subscribeTokenRefresh((token: any) => {
            if (token) {
              newConfig.headers.Authorization = "Bearer " + token;
              res(apiClient(newConfig));
            } else {
              rej(error);
            }
          });
        });
      }
      try {
        isRefreshing = true;
        const token = await refreshToken(1);
        accessTokenHandler("SET", token?.access?.token, token?.access?.expires);
        refreshTokenHandler("SET", token?.refresh?.token, token?.refresh?.expires);
        apiClient.defaults.headers["Authorization"] = "Bearer " + token.access.token;
        newConfig.headers.Authorization = "Bearer " + token.access.token;
        onRefreshed(token.access.token);
        return apiClient(newConfig);
      } catch (error) {
        onRefreshed(undefined);
        logoutUser();
      } finally {
        isRefreshing = false;
      }
    }
    // logout for authorized user.
    if (error.response && error.response?.status === 401) {
      logoutUser();
    }
    if (error.response?.status >= 500) {
      api.miscellaneous.postLogs({
        url: "brewer-portal/500-status-error",
        data: { error: error.response, path: window.location.pathname },
      });
      logoutUser();
    }

    return Promise.reject(error);
  }
);

const apiPrefix = "brewer-portal";

const api = {
  general: {
    getStatus: () => apiClient.get("/status"),
  },
  auth: {
    login: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/login"), body, config),
    register: (body: RegisterApiPayload, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/register"), body, config),
    forgotPassword: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/forgot-password"), body, config),
    resetPassword: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/reset-password"), body, config),
  },
  home: {
    data: (config?: AxiosRequestConfig): Promise<AxiosResponse> => apiClient.get(apiPrefix.concat("/data"), config),
  },
  user: {
    data: (config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/brewer-promotion"), config),
  },
  promotion: {
    add: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/promotion"), body, config),
    update: (id: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.patch(apiPrefix.concat("/promotion/").concat(id), body, config),
    submit: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/promotion/update-status"), body, config),
    getById: (id: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/promotion/user?promotionId=").concat(id), config),
    list: (query: string = "", config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/promotion/user?sort=submissionDate: DESC&").concat(query), config),
    deleteById: (id: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.delete(apiPrefix.concat("/promotion/").concat(id), config),
  },
  program: {
    list: (query: string = "", config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/program/list").concat(query), config),
    getById: (id: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/program/brewer/").concat(id), config),
    upsert: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/promotion/add/promotion-program"), body, config),
    delete: (id: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.delete(apiPrefix.concat("/promotion/promotion-program/").concat(id), config),
    // getById: (id: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
    //   axios.get(`http://localhost:3014/${id}`),
  },
  category: {
    list: (config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/list?moduleType=category"), config),
  },
  contactUs: {
    add: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(apiPrefix.concat("/contact-us"), body, config),
  },
  faq: {
    list: (config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/setting?type=brewer_faq"), config),
  },
  termsCondition: {
    data: (config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/setting?type=brewer_term"), config),
  },
  miscellaneous: {
    refreshToken: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      axios.post(envConfig.API_BASE_URL + apiPrefix.concat("/refresh-token"), body, config),
    verifyToken: (body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      axios.post(envConfig.API_BASE_URL + apiPrefix.concat("/verify-token"), body, config),
    postLogs: (body: PostLogApiPayload, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.post(envConfig.API_BASE_URL + apiPrefix.concat("/post-logs"), body, config),
    getBrandList: (config?: AxiosRequestConfig): Promise<AxiosResponse> =>
      apiClient.get(apiPrefix.concat("/list?moduleType=BREWER_NAME"), config),
  },
};

export default api;
