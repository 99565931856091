import { memo } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Input } from "../../components/ui/input";
import { ICategory } from "src/types/category.type";
import searchIcon from "src/assets/images/search_Icon.svg";
interface Props {
  categories: Array<ICategory>;
  handleFilter: (filter: string) => void;
  onChange: (val: string) => void;
  search: string;
  activeCategoryFilters: string;
}

function ProductSidebarFilters({ categories, handleFilter, onChange, search, activeCategoryFilters }: Props) {
  const filters = [
    {
      label: "Search",
      search: true,
      id: 1,
    },
    {
      label: "Category",
      search: false,
      data: categories.sort((a, b) =>
        activeCategoryFilters?.includes(a?.name?.concat(",")) && activeCategoryFilters?.includes(b?.name?.concat(","))
          ? a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : 1
          : activeCategoryFilters?.includes(a?.name?.concat(","))
            ? -1
            : !activeCategoryFilters?.includes(a?.name?.concat(",")) &&
                !activeCategoryFilters?.includes(b?.name?.concat(","))
              ? a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 1
              : 1
      ),
      id: 2,
    },
  ];

  return (
    <>
      {filters.map((filter) => (
        // <Accordion type="single" collapsible className="w-full" defaultValue={filters[0].label} key={filter.id}>
        <div className="w-full"  key={filter.id}>
          <>
          {/* <AccordionItem value={filter.label} className="border-b-[1px] border-solid border-[#e2e2e2]">
          
          <AccordionTrigger className="AccordionTrigger tracking-[0.25px] py-[20px] text-left hover:no-underline text-[14px] leading-[20px] font-sans w-full font-bold flex"> */}
            <div className="border-b-[1px] border-solid border-[#e2e2e2]">
              <div className="AccordionTrigger tracking-[0.25px] py-[20px] text-left hover:no-underline text-[14px] leading-[20px] font-sans w-full font-bold flex">
                {filter.label}
              </div>
              {/* </AccordionTrigger> */}


             {/* <AccordionContent className="pb-0"> */}

              <div className="pb-0">
                <div className="flex">
                  {filter.search ? (
                    <Input
                      aria-label="Search Brand"
                      type="text"
                      placeholder="Search..."
                      endIcon={
                        <Button className="p-[1px] m-0 bg-[transparent] border-0 h-auto w-auto ">
                          <span className="icon-search flex-shrink-0">
                            <img
                              src={searchIcon}
                              alt="Icon"
                              height={18}
                              width={18}
                              className="xs:h-[18px] xs:w-[18px] h-[16px] w-[16px]"
                            />
                          </span>
                        </Button>
                      }
                      value={search}
                      onChange={(event) => onChange(event?.target.value)}
                      onKeyDown={(event) => {
                        if (event.key == "Enter" && search.length > 0) {
                          onChange(search);
                        }
                      }}
                      className="flex-row-reverse rounded-[50px] h-[50px] border-[#d6d6d6] text-[#4A4F55] text-[14px] px-[15px] py-[8px] mb-[30px] [&>div]:!relative [&>div]:!right-0 [&>div]:!h-[18px] [&_input]:pl-[11px] [&_input]:pr-[0] [&_input]:w-[98%] "
                    />
                  ) : (
                    <ul className="mb-[20px]">
                      {filter.data!.length ? (
                        filter.data!.map((item) => (
                          <li
                            key={item.id}
                            data-active={activeCategoryFilters?.includes(item?.name?.concat(","))}
                            className="flex items-center mdtab:items-center font-sans font-normal mb-[10px] text-[#4A4F55] text-[14px] leading-[18px] hover:text-[#B95804]"
                            onClick={() => handleFilter(item.name)}
                          >
                            <Checkbox
                              id={`beer_filter${item.id}`}
                              checked={activeCategoryFilters?.includes(item?.name?.concat(","))}
                              className=" w-[18px] h-[18px] bg-white rounded-[5px] border-[#d6d6d6] mr-[5px] 2xs:mr-[10px] [&[data-state='checked']]:bg-black [&[data-state='checked']]:border-black [&[data-state='checked']]:text-white [&>[data-state='checked']>svg]:h-[10px]  [&>[data-state='checked']>svg]:w-[10px] [&>[data-state='checked']>svg]:stroke-[4px]"
                            />
                            <span className="cursor-pointer flex justify-center text-[10px] 3xs:text-[12px] 2xs:text-[14px]">
                              {item.name}
                            </span>
                          </li>
                        ))
                      ) : (
                        <li className="flex items-start lg:items-center font-sans font-normal mb-[10px] text-[#4A4F55] text-[14px]">
                          <span> No Results</span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
                {/* </AccordionContent>
                </AccordionItem> */}
              </div>
            </div>
          </>
          {/* </Accordion> */}
        </div>
      ))}
    </>
  );
}

export default memo(ProductSidebarFilters);
