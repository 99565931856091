import UserLogin from "./userLogin";
import BeerStoreIcon from "../../assets/images/beerlogo.svg";
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <div className={`sticky top-0 left-0 w-full z-[99] py-5 px-10 md:px-[30px] lg:px-20 bg-[#000]`} >
      <div className="flex justify-between gap-2">
        <div>
          <Link to={"/"}>
            <img src={BeerStoreIcon} alt={"beerlogo"} />
          </Link>
        </div>
        <UserLogin />
      </div>
    </div>
  );
}
