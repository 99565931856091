import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import * as z from "zod";
import { useEffect, useState } from "react";
import { registerSchema } from "../../lib/validationSchema/validation";
import api from "src/services/api";
import PageRoutes from "src/constants/pageRoutes";
import { exceptionHandler } from "src/utils/helper";
import { ErrorMessages } from "src/constants/errorMessages";
import { useAlertStore } from "src/store/alert";
import AuthWrapper from "src/modules/authWrapper";
import { registerFormFields } from "src/constants";
import SearchBar from "src/modules/searchBar";

const RegisterPage = () => {
  // const [brands, setBrands] = useState([]);
  // const validationSchema = registerSchema(brands);
  const form = useForm<z.infer<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      phone: "+1",
    },
    mode: "onChange",
  });
  const [loading, setLoading] = useState(true);

  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const { setAlert } = useAlertStore();

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      setIsRegistering(true);
      const res = await api.auth.register({ ...data, phone: data.phone.substring(2) });
      if (res.status == 200 || res.status == 201) {
        navigate(PageRoutes.LOGIN);
        setAlert({ variant: "successfully", message: res.data.message });
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setIsRegistering(false);
    }
  });

  // // Fetch Brands
  // useEffect(() => {
  // (async () => {
  // try {
  // setLoading(true);
  // const res = await api.miscellaneous.getBrandList();
  // if (res.status == 200) {
  // if (res?.data?.data && Array.isArray(res.data.data)) {
  // setBrands(res.data.data.map((item: any) => item.name));
  // return;
  //         }
  //       }
  //       throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
  // } catch (error) {
  // exceptionHandler(error, setAlert, "destructive");
  // navigate(PageRoutes.LOGIN);
  // } finally {
  // setLoading(false);
  // }
  //   })();
  // }, []);

  const formKeyDown = (e: any) => {
    if (e.key == "Enter" && e.target.name == "name" && document.getElementsByClassName("dropDownContainer").length) {
      e.preventDefault();
    }
  };

  return (
    <AuthWrapper>
      <div className="flex justify-center w-full centered shrink px-[40px] py-[5px] h-[100vh]">
        <div
          className={`max-w-[400px] w-full flex flex-col justify-center custom-container-one
           gap-[10px] 
        `}
        >
          <div>
            <h2
              className={
                "text-[24px] md:text-[28px] xl:text-[32px] text-center leading-[42px]  font-GothamBold text-[#000]"
              }
            >
              Register
            </h2>
            <p className="font-sans text-[#4A4F55] text-[16px] leading-[24px] mt-[10px]">It's great to see you.</p>
          </div>
          <Form {...form}>
            <form
              onSubmit={onSubmit}
              onKeyDown={formKeyDown}
              className="[&_.error-msg]:text-[12px]  [&_.error-msg]:text-[#bf1332] 
            [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.reply-input>input]:text-[#4A4F55] [&_.reply-input>input]:placeholder:text-[14px] [&_.form-label]:text-[14px] [&_.form-label]:text-[#4A4F55] [&_.form-label]:font-normal [&_.form-label]:font-sans 
            [&_.form-label]:leading-5 [&_.form-label]:text-start [&_.form-itm]:mb-[10px] [&_.form-itm]:text-start"
            >
              {registerFormFields.map((item) => (
                <FormField
                  key={item.id}
                  control={form.control}
                  name={item.name}
                  render={({ field }) => (
                    <FormItem className="form-itm">
                      <FormLabel htmlFor={item.name} className="form-label">
                        {item.label}
                        {item.required && (
                          <sup className="text-[#AD2F33] text-[14px] align-text-bottom font-sans">*</sup>
                        )}
                      </FormLabel>

                      {/* {item.search ? (
                        <SearchBar
                          {...field}
                          placeholder={item.placeHolder}
                          className={`reply-input ${form.formState.errors[item.name] ? "border-[#bf1332]" : "border-[#d6d6d6]"}`}
                          data={brands}
                          showSearchIcon={false}
                          onChange={field.onChange}
                          loading={loading}
                        />
                      ) : ( */}
                      <Input
                        {...field}
                        maxLength={item.maxLength}
                        id={item.id}
                        disabled={isRegistering}
                        value={item?.format?.(field.value) || field.value}
                        className={`reply-input ${form.formState.errors[item.name] ? "border-[#bf1332]" : "border-[#d6d6d6]"}`}
                        placeholder={item.placeHolder}
                      />
                      {/* )} */}
                      <FormMessage className="error-msg" />
                    </FormItem>
                  )}
                />
              ))}
              <div>
                <Button
                  type="submit"
                  className={`w-full uppercase font-bold leading-5 rounded-[200px] py-[15px] px-[30px] text-[16px] h-[50px] mt-[24px] mb-[15px] font-GothamBold
                `}
                  disabled={isRegistering}
                >
                  {isRegistering ? `Please Wait...` : `Register`}
                </Button>
                <p className="font-sans text-[14px] text-[#4A4F55] leading-5 mb-[10px]">
                  Already have account?{" "}
                  <span className="hover:underline text-[#D06F1A] font-GothamBold ">
                    <Link to={PageRoutes.LOGIN}>Login</Link>
                  </span>
                </p>
                <div className="text-center">
                  <p className="font-sans text-[14px] text-[#4A4F55]">© 2024 The Beer Store</p>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </AuthWrapper>
  );
};
export default RegisterPage;
