import * as z from "zod";

const requiredMessage = "This field is required.";

const phoneRegex = /^[(][1-9][0-9]{2}[)][ ][0-9]{3}[-][0-9]{4}$/;

export const contactUsSchema = z.object({
  firstName: z
    .string({
      required_error: requiredMessage,
    })
    .min(1, { message: requiredMessage })
    .refine((value) => /^[a-zA-Z\s]+$/.test(value), {
      message: "Please enter letters only.",
    })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  lastName: z
    .string({
      required_error: requiredMessage,
    })
    .min(1, { message: requiredMessage })
    .refine((value) => /^[a-zA-Z\s]+$/.test(value), {
      message: "Please enter letters only.",
    })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  email: z.string().min(1, { message: requiredMessage }).email("Please enter a valid email address."),
  // contactOption: z
  //   .string({
  //     required_error: "This field is required.",
  //   })
  //   .refine((value) => value, {
  //     message: "This field is required.",
  //   }),
  comment: z
    .string({
      required_error: requiredMessage,
    })
    .refine((value) => value, {
      message: requiredMessage,
    })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  recaptcha: z
    .string({
      required_error: requiredMessage,
    })
    .refine((value) => value, {
      message: requiredMessage,
    }),
});

export const programSchema = z
  .object({
    cycle: z.string().optional(),
    zone: z.array(z.object({ id: z.number(), label: z.string() })).optional(),
    cycleIndex: z.number().optional(),
    storeListCsvUrl: z.string().optional(),
    storesFile: z.instanceof(File).nullable().optional(),
    type: z.enum(["ZONE", "STORE_LIST", "ONLY_CYCLE", "ZONE_STORE_LIST"]).default("ZONE"),
  })
  .superRefine(({ cycle, zone, type, storeListCsvUrl, storesFile }, ctx) => {
    if (cycle && type === "ZONE") {
      if (!Array.isArray(zone) || !zone.length) {
        ctx.addIssue({
          code: "custom",
          message: "Please select at least one zone.",
          path: ["zone"],
        });
      }
    }
    if (cycle && type === "STORE_LIST") {
      if (!storeListCsvUrl && !storesFile) {
        ctx.addIssue({
          code: "custom",
          message: "Please upload the store list.",
          path: ["storesFile"],
        });
      }
    }
  });

export const promotionSchema = z.object({
  brandName: z
    .string()
    .min(1, { message: requiredMessage })
    .max(25, { message: "Brand name must be under 25 characters." })
    // .refine((val) => brandList.some((item) => item.toLowerCase() == val.toLowerCase()), {
    //   message: "Invalid brand name!",
    // })
    .transform((val) => val.trim()),
  container: z
    .string()
    .min(1, { message: requiredMessage })
    .max(25, { message: "Container must be under 25 characters." })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  // volume: z.string().min(1, { message: "This field is required." })
  //   .max(25, { message: "Volume must be under 25 characters." })
  //   .refine((val) => val.trim().length, { message: "Please enter letters only." })
  //   .transform(val => val.trim()),
  articleNumber: z
    .string()
    .min(1, { message: requiredMessage })
    .max(25, { message: "Article Number must be under 25 characters." })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  skuPricing: z
    .string()
    .min(1, { message: requiredMessage })
    .refine((data) => +data > 0 && +data <= 1000, { message: "Price must be greater than $0 and not exceed $1000." })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  offers: z
    .string()
    .min(1, { message: requiredMessage })
    .max(25, { message: "Offers must be under 25 characters." })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
  // promoCase: z.string()
  //   .min(1, { message: "This field is required." })
  //   .max(25, { message: "Promo case must be under 25 characters." })
  //   .refine((val) => val.trim().length, { message: "Please enter letters only." })
  //   .transform(val => val.trim()),
  packSize: z
    .string()
    .min(1, {
      message: requiredMessage,
    })
    .refine((val) => val.trim().length && !isNaN(+val) && +val > 0, { message: "Invalid pack size." })
    .transform((val) => val.trim()),
  advertisingSummary: z
    .string()
    .min(1, {
      message: requiredMessage,
    })
    .max(300, { message: "Advertising Summary must be under 300 characters." })
    .refine((val) => val.trim().length, { message: "Please enter letters only." })
    .transform((val) => val.trim()),
});

export const registerSchema = z.object({
  email: z.string({ required_error: requiredMessage }).email("Please enter a valid email address."),
  phone: z.string().min(16, { message: "Invalid phone number (i.e (999) 777-9977)" }),
  firstName: z
    .string()
    .min(1, { message: requiredMessage })
    .refine((value) => /^[a-zA-Z]+$/.test(value), {
      message: "Please enter letters only.",
    })
    .transform((val) => val.trim()),
  lastName: z
    .string()
    .min(1, { message: requiredMessage })
    .refine((value) => /^(?!\s)(?!.*\s$)[a-zA-Z\s]+$/.test(value), {
      message: "Please enter letters only.",
    })
    .transform((val) => val.trim()),
    name: z
    .string()
    .min(1, { message: requiredMessage })
    .refine((value) => /^(?!\s)(?!.*\s$)[a-zA-Z\s']+$/.test(value), {
      message: "Please enter letters only.",
    })
    .transform((val) => val.trim()),
  // .refine((val) => brandList.some((item) => item.toLowerCase() == val.toLowerCase()), {
  //   message: "Invalid brand name!",
  // }),
});
// export const registerValidationType = registerSchema([]);
export const loginSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: requiredMessage,
    })
    .email("Please enter a valid email address."),
  password: z.string().min(1, {
    message: requiredMessage,
  }),
});

export const resetPasswordSchema = z
  .object({
    password: z
      .string({
        required_error: requiredMessage,
      })
      .min(
        1,
        // 'Password must contain a minimum of 8 characters.'
        requiredMessage
      )
      .min(
        8,
        // 'Password must contain a minimum of 8 characters.'
        "Password must contain a minimum of 8 characters, at least 1 letter, 1 number, and 1 special character (!,@,#,$,%,^,&,*)."
      )
      .refine(
        (value) => /\d/.test(value),
        // 'Password must contain at least 1 number.',
        "Password must contain a minimum of 8 characters, at least 1 letter, 1 number, and 1 special character (!,@,#,$,%,^,&,*)."
      )
      .refine(
        (value) => /[a-zA-Z]/.test(value),
        // 'Password must contain at least 1 letter.',
        "Password must contain a minimum of 8 characters, at least 1 letter, 1 number, and 1 special character (!,@,#,$,%,^,&,*)."
      )
      .refine(
        (value) => /[!@#$%^&*]/.test(value),
        // 'Password must contain at least 1 special character (!,@,#,$,%,^,&,*).',
        "Password must contain a minimum of 8 characters, at least 1 letter, 1 number, and 1 special character (!,@,#,$,%,^,&,*)."
      ),
    confirmPassword: z
      .string({
        required_error: requiredMessage,
      })
      .min(
        1,
        // 'Password must contain a minimum of 8 characters.'
        requiredMessage
      ),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"],
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match.",
        path: ["confirmPassword"],
      });
    }
  });
