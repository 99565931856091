import GreenCheck from "../../assets/images/greenCheck.svg";
import { Button } from "./button";
import { useNavigate } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";
export default function ThankYou({ className }: any) {
  const navigate = useNavigate();
  return (
    <div className={`max-w-[700px] m-auto ${className}`}>
      <div className="flex flex-col items-center gap-[30px] border-[1px] rounded-[10px] px-[40px] py-[60px]">
        <div>
          <img src={GreenCheck} alt="check" />
        </div>
        <div className="flex flex-col items-center gap-[10px]">
          <h2 className="text-[40px] font-GothamBold leading-[50px]">Thank You</h2>
          <p className="text-[#4A4F55] font-sans text-center text-[16px] leading-[24px]">
            Your application has been submitted successfully.
            <br />
            You will be contacted by the Brewer Programs Team.
          </p>
        </div>
        <div>
          <Button
            onClick={() => navigate(PageRoutes.PROMOTION)}
            className="rounded-[30px] uppercase py-[15px] px-[30px] font-GothamBold text-[14px] h-auto max-h-[50px]"
          >
            Start a new application
          </Button>
        </div>
      </div>
    </div>
  );
}
