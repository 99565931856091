import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import { Separator } from "../../components/ui/separator";
import { Form } from "../../components/ui/form";
import { programSchema } from "../../lib/validationSchema/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Dummy from "../../assets/images/dummy.png";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import Cycle from "./cycle";
import Zone from "./zone";
import { useEffect, useRef, useState } from "react";
import InfoCard from "../../components/infoCard";
import { useNavigate, useParams } from "react-router-dom";
import ParticipatingStore from "./participatingStore";
import api from "../../../src/services/api";
import { ErrorMessages } from "../../../src/constants/errorMessages";
import { exceptionHandler } from "../../../src/utils/helper";
import { useAlertStore } from "../../../src/store/alert";
import PageRoutes from "../../../src/constants/pageRoutes";
import { usePromotionStore } from "../../../src/store/promotion";
import { IProgram } from "../../../src/types/program.type";
import ProgramSaveChangesConfirmationModal from "src/modules/modal/saveChangesConfirmationModal";
import { cn } from "src/lib/utils";
import { SuccessMessages } from "src/constants/successMessages";
import { UNSAVED_PROGRAM_CONFIRMATION_TEXT } from "src/constants";

const DEFAULT_FORM_VALUES = {
  cycle: "",
  cycleIndex: undefined,
  zone: [],
  storeListCsvUrl: "",
};

interface SaveChangesConfirmationModalRef {
  toggleOpen: (url?: string) => void;
}
export default function ProgramDetail() {
  const form = useForm<z.infer<typeof programSchema>>({
    resolver: zodResolver(programSchema),
    defaultValues: DEFAULT_FORM_VALUES,
    mode: "onChange",
  });
  const { id: programId } = useParams();
  const [program, setProgram] = useState<IProgram | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setAlert } = useAlertStore();
  const { promotion, setPromotion } = usePromotionStore();
  const [selectedCycleIdx, setSelectedCycleIdx] = useState(0);
  const saveConfirmationModalRef = useRef<SaveChangesConfirmationModalRef>();
  // const [openGobackModal, setOpenGobackModal] = useState(false);
  const [programHistory, setProgramHistory] = useState<Partial<z.infer<typeof programSchema>> | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const breadcrumb = [
    {
      id: "1",
      key: "Home",
      value: PageRoutes.HOME,
    },
    {
      id: "2",
      key: "Program",
      value: PageRoutes.PROGRAM,
    },
    {
      id: "3",
      key: program?.name || "",
      value: programId,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const res = await api.program.getById(programId!);
        if (res.status === 200) {
          const data = res.data;
          setProgram(data);
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error: any) {
        exceptionHandler(error, setAlert, "destructive");
        navigate(PageRoutes.PROGRAM);
      } finally {
        setLoading(false);
      }
    })();
  }, [programId]);

  useEffect(() => {
    try {
      if (promotion.program) {
        const addedProgram = promotion.program.find((item) => item?.programId.toString() == programId);
        if (addedProgram) {
          const idx = program?.cycle.findIndex((item) => item?.name == addedProgram?.cycle.year) as number;
          if (!isNaN(idx) && idx > -1) {
            setSelectedCycleIdx(idx);
            const cycleIndex = program?.cycle[idx].data.findIndex(
              (item) => item?.isAvailable && item?.programCycleId == addedProgram.cycle.programCycleId
            ) as number;
            if (!isNaN(cycleIndex) && cycleIndex > -1) {
              const cycle = program?.cycle[idx]?.data[cycleIndex!];

              // Setting default fields.
              form.setValue("cycle", addedProgram.cycle.programCycleId.toString());
              form.setValue("cycleIndex", cycleIndex);
              form.setValue("type", cycle?.cycleType || "ONLY_CYCLE");
              const partialProgramHistory: Partial<z.infer<typeof programSchema>> = {
                ...programHistory,
                cycle: addedProgram.cycle.programCycleId.toString(),
                cycleIndex,
                type: cycle?.cycleType || "ONLY_CYCLE",
              };

              if (
                cycle?.cycleType &&
                cycle?.cycleType == "ZONE" &&
                Array.isArray(addedProgram?.promotionZoneStore) &&
                addedProgram.promotionZoneStore.length
              ) {
                const zones: any = cycle.zones.reduce(
                  (prev, next) => ({ ...prev, [next?.programZoneId || ""]: next }),
                  {}
                );
                const zone = addedProgram?.promotionZoneStore
                  .filter((item) => !!zones[item?.programZoneId]?.isAvailable)
                  .map((item) => ({ id: item?.programZoneId, label: item?.name }));
                form.setValue("zone", zone);
                partialProgramHistory.zone = zone;
              }
              if (
                cycle?.cycleType &&
                cycle?.cycleType == "STORE_LIST" &&
                addedProgram.promotionZoneStore.length === 1
              ) {
                const storeList = addedProgram?.promotionZoneStore[0]?.storeExcel || "";
                form.setValue("storeListCsvUrl", storeList);
                partialProgramHistory.storeListCsvUrl = storeList;
              }
              setProgramHistory(partialProgramHistory);
              setIsUpdate(true);
            }
          }
        }
      }
    } catch (error) {
      console.error("ERROR WHILE SETTING UP DEFAULT VALUES", error);
    }
  }, [promotion.id, program?.id]);

  const tabChangeHandler = (name: string) => {
    const newIdx = program?.cycle.findIndex((item) => item?.name == name) as number;

    if (!isNaN(newIdx) && newIdx > -1) {
      form.reset({
        ...DEFAULT_FORM_VALUES,
        type: form.getValues("type"),
        storeListCsvUrl: form.getValues("storeListCsvUrl"),
      });
      setSelectedCycleIdx(newIdx);
    }
  };

  const onSubmit = form.handleSubmit(async (data) => {
    if (!data.cycle) {
      navigate(PageRoutes.promotionDetail(promotion.id));
      // form.setError("cycle", { message: "Please select cycle.", type: "required" });
      return;
    }
    await upsertProgram(data, () => {
      navigate(PageRoutes.promotionDetail(promotion.id));
    });
  });

  function isProgramUpdated(formData: z.infer<typeof programSchema> = form.getValues()) {
    let is = false;
    if (!programHistory) {
      return true;
    }
    for (const [key] of Object.entries(formData)) {
      // @ts-ignore
      const curr = formData[key];
      // @ts-ignore
      const prev = programHistory[key];
      if (curr && !prev && key == "storesFile") {
        is = true;
        break;
      }
      if (curr && prev && JSON.stringify(curr) != JSON.stringify(prev)) {
        is = true;
        break;
      }
    }
    return is;
  }

  const upsertProgram = async (data: z.infer<typeof programSchema> = form.getValues(), callback: Function) => {
    if (!isProgramUpdated(data)) {
      callback();
      return;
    }
    setLoading(true);
    try {
      const obj: any = {
        promotionId: promotion.id,
        cycle: data.cycle,
        programType: data.type,
        programId: programId,
        zones: JSON.stringify(data.zone?.map((item) => item?.id)),
        file: data.storesFile,
        storeUrl: data.storeListCsvUrl,
      };
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      const res = await api.program.upsert(formData!, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200 || res.status === 201) {
        setPromotion(res.data);
        callback();
        setAlert({
          message: isUpdate ? SuccessMessages.PROGRAM_UPDATED_SUCCESS : SuccessMessages.PROGRAM_ADDED_SUCCESS,
          variant: "successful",
        });
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setLoading(false);
    }
  };

  const onCancelHandler = (route: string) => {
    saveConfirmationModalRef.current?.toggleOpen();
  };
  const onSaveHandler = (route: string) => {
    navigate(route);
  };

  const addMoreProgramHandler = form.handleSubmit(
    async (data) => {
      // on remove
      if (!data.cycle) {
        const isExisted = promotion?.program?.find((item) => item?.programId?.toString() == programId);
        if (isExisted) {
          // skipping program submission.
        }
        navigate(PageRoutes.PROGRAM);
      } else {
        await upsertProgram(undefined, () => {
          navigate(PageRoutes.PROGRAM);
        });
      }
    },
    () => {
      saveConfirmationModalRef.current?.toggleOpen(PageRoutes.PROGRAM);
    }
  );

  return (
    <>
      <FullPageTemplate loading={loading} breadcrumbList={breadcrumb}>
        {/* <div
          className="flex flex-row gap-[10px] mb-[20px] font-GothamMedium cursor-pointer"
          onClick={() => saveConfirmationModalRef.current?.toggleOpen(PageRoutes.PROGRAM)}
        >
          <img src={BackArrow} alt="backarrow" />
          <p className="text-[#F4B233]">Back to Home</p>
        </div> */}
        <div className="flex flex-col mdtab:flex-row relative pb-[30px] xs:pb-[48px] md:pb-[50px] lg:pb-[60p] xxl:pb-[70px] 2xl:pb-[80px] gap-[30px] md:gap-[40px] mdtab:gap-[60px]">
          <div className=" md:p-0 md:left-0 md:relative md:transition-none md:visible flex-0 md:z-auto md:basis-2/6 lg:basis-1/4 w-full">
            <div className="main-info md:py-0">
              <InfoCard
                title={program?.name || ""}
                description={program?.description || ""}
                image={{
                  url: program?.image || Dummy,
                  alt: program?.name,
                  width: 355,
                  height: 232,
                }}
                badgeText={program?.category}
                className={`equal-h flex-col flex shadow-none rounded-[10px] border-[1px] overflow-hidden [&_.info-content]:py-[20px] md:[&_.info-content]:!p-[30px]  
              [&_.info-content]:!px-[20px] [&_.info-content>div>h3]:mb-[16px] [&_.info-content>div>h3]:pb-[0px] [&_.info-content>div>h3]:!leading-[24px] md:[&_.info-content>div>h3]:!leading-[34px]
              [&_.info-header]:max-h-[295px]
                            xl-1440:[&_.info-image]:h-[100%]
                            [&_.info-description]:text-[14px]
              [&_.info-content>]:.eq-height [&>div>div>a]:font-bold [&>div>div>a]:uppercase [&>div>img]:rounded-[10px] [&_.info-content>div>h3]:text-[16px]  
              md:[&_.info-content>div>h3]:text-[24px] [&_.info-content>div>h3]:font-GothamBold
                            [&_.info-header]:relative
                            [&_.info-header]:overflow-hidden
                            [&>div>img]:absolute [&>div>img]:top-0 [&>div>img]:left-0 [&>div>img]:object-contain
                            3xs:[&_.info-title]:max-w-[370px] 3xs:[&_.info-title]:w-[370px] info-height [&_.info-footer]:p-[2px] [&_button]:!w-auto `}
              />
            </div>
          </div>
          <div>
            <Separator orientation="vertical" className="bg-[#d6d6d6]" />
          </div>
          <div className="basis-full md:basis-4/6 lg:basis-3/4">
            <div className="flex flex-col gap-[30px] max-w-[944px] m-auto">
              <Form {...form}>
                <form
                  className="flex flex-col [&_.error-msg]:text-[12px] [&_.error-msg]:text-[#bf1332] 
                  [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.form-label]:text-[14px] 
                  [&_.form-label]:font-normal  [&_.form-label]:text-[#4A4F55] [&_.form-label]:font-sans [&_.form-label]:leading-5 [&_.form-itm]:mb-5 [&_.form-checkbox]:my-[10px] [&_.reply-input>input]:placeholder:text-[#4A4F55] [&_.form-itm>button>span]:text-[#4A4F55] [&_.form-itm>textarea]:placeholder:text-[#4A4F55] [&_.reply-input>input]:text-[#4A4F55] [&_.form-itm>textarea]:text-[#4A4F55]"
                  onSubmit={onSubmit}
                >
                  <div className="flex flex-col gap-10">
                    <div className={"flex flex-col gap-[30px]"}>
                      <div className={"flex flex-col"}>
                        <h2 className="text-[20px] font-GothamBold 3xs:text-[24px] xs:text-[24px] md:text-[24px] lg:text-[24px] xl:text-[24px]  leading-[34px] 3xs:leading-[34px] xs:leading-[34px] md:leading-[34px] lg:leading-[34px] xl:leading-[34px] text-[#000] m-0 flex items-center">
                          {"Select a Cycle"}
                        </h2>
                      </div>
                      {program && (
                        <Cycle
                          cycles={program?.cycle || []}
                          onTabChange={tabChangeHandler}
                          selectedCycleData={program.cycle[selectedCycleIdx]}
                          form={form}
                        />
                      )}
                    </div>
                    {program && form.watch("cycle") ? (
                      form.watch("type") == "STORE_LIST" && program?.storeCsvUrl ? (
                        <>
                          <Separator orientation="horizontal" className="bg-[#d6d6d6]" />
                          <ParticipatingStore url={program.storeCsvUrl} form={form} />
                        </>
                      ) : form.watch("type") == "ZONE" && form.watch("cycleIndex") !== undefined ? (
                        <>
                          <Separator orientation="horizontal" className="bg-[#d6d6d6]" />
                          <Zone
                            form={form}
                            zone={program.cycle[selectedCycleIdx]!.data[form.watch("cycleIndex")!]?.zones || []}
                            limit={program?.limit}
                            guideUrl={program.guideUrl}
                          />
                        </>
                      ) : null
                    ) : (
                      <>
                        <Separator orientation="horizontal" className="bg-[#d6d6d6]" />
                        <div className="mb-[0px] [&_p]:text-[18px] [&_p]:font-GothamMedium">
                          <p className={cn(form?.formState?.errors?.cycle?.message ? "#bf1332" : "", "text-center")}>
                            Please select cycle.
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`contact-btn flex justify-center mdtab:justify-end mx-0 gap-[30px] mdtab:gap-[15px] lg-1200:gap-[30px] mt-[40px]`}
                  >
                    <div>
                      <Button
                        variant={"secondary"}
                        type="button"
                        className="uppercase h-[50px] md:w-full text-[12px] leading-[15px] mdtab:text-[10px] mdtab:leading-[13px] lg-1200:text-[11px] lg-1200-leading-[14px] xl:text-[13px] xl:leading-[16px] xl-1365:text-[14px] xl-1365:leading-[17px] font-GothamBold rounded-[200px] px-[15px] xl-1300:px-[20px] xl-1365:px-[30px] py-[15px] mdtab:py-[5px] lg:py-[15px] h-[50px]items-baseline"
                        onClick={addMoreProgramHandler}
                      >
                        Save and add more programs
                      </Button>
                    </div>
                    {/* <div>
                      <Button
                        variant={"secondary"}
                        type="button"
                        className="uppercase h-[50px] md:w-full text-[12px] leading-[15px] mdtab:text-[10px] mdtab:leading-[13px] lg-1200:text-[11px] lg-1200-leading-[14px] xl:text-[13px] xl:leading-[16px] xl-1365:text-[14px] xl-1365:leading-[17px] font-GothamBold rounded-[200px] px-[15px] xl-1300:px-[20px] xl-1365:px-[30px] py-[15px] mdtab:py-[5px] lg:py-[15px] h-[50px]items-baseline"
                        onClick={addMoreProgramHandler}
                      >
                        Add more programs
                      </Button>
                    </div> */}
                    <div>
                      <Button
                        type="submit"
                        className="uppercase h-[50px] md:w-full text-[12px] leading-[15px] mdtab:text-[10px] mdtab:leading-[13px] lg-1200:text-[11px] lg-1200-leading-[14px] xl:text-[13px] xl:leading-[16px] xl-1365:text-[14px] xl-1365:leading-[17px] font-GothamBold rounded-[200px] px-[15px] xl-1300:px-[20px] xl-1365:px-[30px] py-[15px] mdtab:py-[5px] lg:py-[15px] h-[50px]items-baseline"
                      >
                        Review Promotion and Programs
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </div>
      </FullPageTemplate>
      <ProgramSaveChangesConfirmationModal
        title={UNSAVED_PROGRAM_CONFIRMATION_TEXT}
        ref={saveConfirmationModalRef}
        onCancel={onCancelHandler}
        onSubmit={onSaveHandler}
      />
    </>
  );
}
