import React from "react";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";
import NotFoundIcon from "../../assets/images/notfound/notfound-text.svg";
import { FullPageTemplate } from "src/modules/programsLayout/fullPageTemplate";
export default function NotFound() {
  const navigate = useNavigate();
  return (
 
    <FullPageTemplate>
      
      {/* <div id="start-of-content" role="complementary" aria-label="Main content Begins Here">
        <label className="">Content Starts Here </label>
      </div> */}
      <div className=" text-center py-[48px] px-[25px] md:w-[600px] md:py-[80px] m-auto">
        <img src={NotFoundIcon} alt="Not found" width={286} height={129} className="inline-block m-auto" />
        <h2 className="text-[32px] font-GothamBold mt-[26px] mb-[20px] ">Its not you, its us!</h2>
        <p className="leading-[24px] mb-[16px]">{`We just can’t find the page you’re looking for.`}</p>
        <Button
          className="rounded-full inline-block text-[16px] h-auto max-h-[50px] font-GothamBold py-[15px] px-[22px] sm:px-[26px] md:px-[30px] leading-[18px] uppercase"
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </div>
 
    </FullPageTemplate>
  );
}