import { useEffect } from "react";
import { Alert, AlertDescription} from "./components/ui/alert";
import Routes from "./routes/index";
import { useAlertStore } from "./store/alert";
import sidebarclose from "../src/assets/images/sidebarclose.svg";
import successIcon from "../src/assets/images/success-icon.svg";
import errorIcon from "../src/assets/images/error-icon.svg";
import envConfig from "./config";
import "./App.css";
 
function App() {
  const { alert, closeAlert } = useAlertStore();
 
  useEffect(() => {
    if (envConfig.env !== "PROD") {
      // @ts-ignore
      window.onUsersnapLoad = function (api: any) {
        api.init();
      };
      var script = document.createElement("script");
      script.defer = true;
      script.src = envConfig.usersnapUrl!;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }, []);
  return (
    <>
      {alert.isOpen && (
        <Alert variant={alert.variant} className="flex flex-row justify-between">
          <div className="flex flex-row justify-between gap-[40px]">
            <div className="flex flex-row place-items-start gap-2 justify-center">
              <img
                src={alert.variant == "successful" || alert.variant == "successfully" ? successIcon : errorIcon}
                alt="alert-icon"
              />
 
                {/* {alert?.title && (
                  <AlertTitle>
                    <span>{alert.title}</span>
                  </AlertTitle>
                )} */}
                <AlertDescription>
              <span>{alert.message}</span>
                </AlertDescription>
              
            </div>
            <div className="flex cursor-pointer" onClick={closeAlert}>
              <img src={sidebarclose} width={15} height={15} />
            </div>
          </div>
        </Alert>
      )}
      <Routes />
    </>
  );
}
 
export default App;