import { useEffect, useState } from "react";
import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import FaqBg from "../../assets/images/faqBg.jpg";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import { Link } from "react-router-dom";
import { useAlertStore } from "src/store/alert";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { exceptionHandler } from "src/utils/helper";
import PageRoutes from "src/constants/pageRoutes";

interface Faq {
  question: string;
  answer: string;
}
export default function FAQ() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlertStore();
  const [faqList, setFaqList] = useState<Faq[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.faq.list();
        if (res.status === 200) {
          if (Array.isArray(res.data?.data)) {
            setFaqList(res.data.data);
          }
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        exceptionHandler(error, setAlert, "destructive");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <FullPageTemplate desktopBg={FaqBg} mobileBg={FaqBg} bannerTitle={"Frequently Asked Questions"} loading={loading}>
      <div className="max-w-[1090px] m-auto 3xs:px-[20px] xs:px-[50px] md:px-[90px] mdtab:px-[135px] pb-[0px]">
        <div className="flex justify-center pb-[60px]">
          <h2 className="text-[24px] text-center align-middle font-GothamBold leading-[34px]">
            Find the answers to all of our most frequently asked questions
          </h2>
        </div>
        <Accordion type="single" collapsible>
          {faqList?.map((item, idx) => (
            <div key={idx}>
              <AccordionItem value={item.question}>
                <AccordionTrigger className="text-[16px] [&_img]:ml-[5px] [&_img]:h-[20px] [&_img]:w-[18px] font-GothamBold text-left py-[20px] hover:no-underline leading-[24px] text-black">
                  {item?.question}
                </AccordionTrigger>
                <AccordionContent className="text-[14px] leading-[20px] font-sans pb-[20px]">
                  {item?.answer}
                </AccordionContent>
              </AccordionItem>
            </div>
          ))}
        </Accordion>
        <div className="flex flex-col gap-[20px] text-center pt-[60px]">
          <h4 className="font-GothamBold text-[20px] leading-[30px]">Have any other question?</h4>
          <p className="leading-[20px] text-[14px]">
            For support, please contact{" "}
            {/* <Link className="text-[#D06F1A] underline font-bold" to={PageRoutes.HOME}> */}
            <Link
              className="text-[#D06F1A] hover:underline font-bold text-[14px] leading-[20px]"
              to="mailto:brewerprograms@thebeerstore.ca"
            >
              brewerprograms@thebeerstore.ca
            </Link>{" "}
          </p>
        </div>
      </div>
    </FullPageTemplate>
  );
}
