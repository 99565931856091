import React, { createContext, useState, useEffect, useContext } from "react";
import { accessTokenHandler, refreshTokenHandler } from "src/utils/cookieHelper";
import api, { apiClient } from "src/services/api";
import { IAuthContext } from "src/types/user.type";
import PageRoutes from "src/constants/pageRoutes";
import { ErrorMessages } from "src/constants/errorMessages";
import { usePromotionStore } from "src/store/promotion";
import { useGlobalLoader } from "src/store/loader";

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  token: null,
  login: () => null,
  logout: () => null,
  user: null,
  loading: false,
});

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [token, setToken] = useState<string | null>(refreshTokenHandler("GET")!);
  const [loading, setLoading] = useState<boolean>(false);
  const { setLoading: gSetLoading } = useGlobalLoader();
  const [user, setUser] = useState<any>(null);
  const { setPromotion } = usePromotionStore();

  useEffect(() => {
    if (token) {
      (async () => {
        setLoading(true);
        gSetLoading(true);
        try {
          const res = await api.user.data();
          if (res.status == 200) {
            setUser(res.data.user);
            setPromotion(res.data.promotion);
          } else {
            throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
          }
        } catch (error) {
          console.error(error);
          logout();
        } finally {
          gSetLoading(false);
          setLoading(false);
        }
      })();
    }
  }, []);

  const login = (data: Record<string, any>) => {
    accessTokenHandler("SET", data.token.access.token, data.token.access.expires);
    refreshTokenHandler("SET", data.token.refresh.token, data.token.refresh.expires);
    setUser(data.userInfo);
    setPromotion(data.userInfo?.promotion);
    setToken(data.token.refresh.token);
    apiClient.defaults.headers["Authorization"] = "Bearer " + data.token.access.token;
  };

  const logout = () => {
    accessTokenHandler("REMOVE");
    refreshTokenHandler("REMOVE");
    setUser(null);
    setToken(null);
    apiClient.defaults.headers["Authorization"] = "";
    window.location.href = PageRoutes.LOGIN;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated: !!token, user, token, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
