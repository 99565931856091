import { registerSchema } from "src/lib/validationSchema/validation";
import { formatPhoneNumber } from "src/utils/helper";
import { z } from "zod";

export const SERVICES_FIELD_OPTIONS = [
  {
    optionname: "Thebeerstore.ca Inquiry",
    optionval: "Thebeerstore.ca Inquiry",
  },
  { optionname: "Mobile App Inquiry", optionval: "Mobile App Inquiry" },
  { optionname: "Social Media Inquiry", optionval: "Social Media Inquiry" },
  { optionname: "Retail Stores Inquiry", optionval: "Retail Stores Inquiry" },
  {
    optionname: "Customer Service Inquiry",
    optionval: "Customer Service Inquiry",
  },
  { optionname: "BDL Inquiry", optionval: "BDL Inquiry" },
  { optionname: "Beer for Business", optionval: "Beer for Business" },
  { optionname: "Media Inquiry", optionval: "Media Inquiry" },
  { optionname: "Other", optionval: "Other" },
];
export const DEFAULT_SERVICES_TEXT = "Choose an option";
export const REMOVE_PROGRAM_CONFIRMATION_TEXT = "Are you sure you want to delete this program?";
export const UNSAVED_PROGRAM_CONFIRMATION_TEXT =
  "You have not filled all the required fields for this program, do you wish to proceed and leave the page?";
export const CONTAINER_OPTIONS = [
  { optionname: "Bottles", optionval: "Bottles" },
  { optionname: "Cans", optionval: "Cans" },
  { optionname: "Other", optionval: "Other" },
];
export const PACKED_SIZE_OPTIONS = [
  { optionname: "Single Pack", optionval: "1" },
  { optionname: "2", optionval: "2" },
  { optionname: "3", optionval: "3" },
  { optionname: "4", optionval: "4" },
  { optionname: "6", optionval: "6" },
  { optionname: "8", optionval: "8" },
  { optionname: "12", optionval: "12" },
  { optionname: "15", optionval: "15" },
  { optionname: "16", optionval: "16" },
  { optionname: "18", optionval: "18" },
  { optionname: "20", optionval: "20" },
  { optionname: "24", optionval: "24" },
  { optionname: "28", optionval: "28" },
  { optionname: "30", optionval: "30" },
  { optionname: "36", optionval: "36" },
  { optionname: "40", optionval: "40" },
  { optionname: "45", optionval: "45" },
  { optionname: "48", optionval: "48" },
  { optionname: "56", optionval: "56" },
  { optionname: "60", optionval: "60" },
];

export const COOKIES_KEYS = {
  ACCESS_TOKEN: "6c53cd5f-at5c-44b4-a695-3ef94e4855c8",
  REFRESH_TOKEN: "5cfdf834-rt1d-4951-aeaa-ad779f72f6c1",
  USER: "d4119207-5c72-4d14-a719-a3f90eff99af",
};

export const registerFormFields: {
  name: keyof z.infer<typeof registerSchema>;
  label: string;
  id: string;
  required: boolean;
  placeHolder: string;
  search?: boolean;
  format?: Function;
  maxLength?: number;
}[] = [
  {
    name: "email",
    label: "Email Address",
    id: "email",
    required: true,
    placeHolder: "Enter Email Address",
    maxLength: 50,
  },
  {
    name: "phone",
    label: "Phone Number",
    id: "phone",
    required: true,
    placeHolder: "Phone Number",
    format: formatPhoneNumber,
    maxLength: 16,
  },
  {
    name: "firstName",
    label: "First Name",
    id: "firstName",
    required: true,
    placeHolder: "First Name",
    maxLength: 31,
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "lastName",
    required: true,
    placeHolder: "Last Name",
    maxLength: 31,
  },
  {
    name: "name",
    label: "Brewer Name",
    id: "name",
    required: true,
    placeHolder: "Brewer Name",
    // search: true,
    maxLength: 50,
  },
];
