import { useRef, useState } from "react";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../assets/images/usericon.svg";
import Arrowdown from "../../assets/images/Arrowdown.svg";
// import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useAuth } from "src/context/authContext";
import PageRoutes from "src/constants/pageRoutes";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { usePromotionStore } from "src/store/promotion";
import NewSubmissionConfirmationModal, {
  NewSubmissionConfirmationModalRef,
} from "src/modules/modal/newSubmissionConfirmationModal";

export default function UserLogin() {
  // const mediaScreen = useMediaQuery({ "max-width": "766.98px" });
  const { isAuthenticated, user, logout, loading } = useAuth();
  const { promotion } = usePromotionStore();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const customRef = useRef<any>();
  const listRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();
  const newSubmissionRef = useRef<NewSubmissionConfirmationModalRef>();

  useOnClickOutside(customRef, () => setShowLoginPopup(false));

  const closePop = (callback: any) => {
    setShowLoginPopup(false);
    callback();
  };

  return (
    <div className="relative" ref={customRef}>
      <div>
        <div className={"flex appearance-none"}>
          <Button
            className={`text-white bg-transparent text-[14px] font-sans font-normal flex md:items-center border-2 border-solid border-white rounded-full h-auto w-[100%] ${!user ? "min-w-max" : "md:min-w-[160px] min-w-[40px]"} pt-[11px] pb-[10px] px-5 sm:px-[20px] gap-[0px]  xs:gap-[10px] ${loading ? "pointer-events-none" : "pointer-events-auto"} `}
            size={"icon"}
            variant="link"
            onClick={() => (isAuthenticated ? setShowLoginPopup(!showLoginPopup) : navigate(PageRoutes.LOGIN))}
          >
            <div className="">
              <img src={UserIcon} alt="user" />
            </div>

            {!loading ? (
              <>
                <p className="font-sans text-[#fff] text-[16px] leading-[1.2] font-medium hidden md:block">
                  {!user ? "Login" : `Hi, ${user?.firstName}`}
                </p>
                {isAuthenticated && (
                  <img
                    height={16}
                    width={16}
                    src={Arrowdown}
                    alt="arrowdown"
                    className={`hidden md:block ${showLoginPopup ? "rotate-180" : ""}`}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </Button>
        </div>
      </div>
      {(!loading && showLoginPopup && (
        <div className={`absolute top-[50px] right-[0%]`}>
          <div className="z-[999] border w-[258px] text-popover-foreground shadow-xl data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2  bg-white p-[20px] relative rounded-[10px] border-[#e2e2e2]">
            <ul
              className="[&>li>a]:text-[16px] [&>li>a]:text-[#4A4F55] [&>li>a]:leading-[30px] [&>li]:list-none [&>li]:cursor-pointer last:[&>li]:border-none last:[&>li]:pb-0 first:[&>li]:pt-0 [&>li>a]:py-[10px] [&>li:last-of-type_a]:!pb-[0px] [&>li:first-of-type_a]:!pt-[0px] [&>li>a]:block [&>li>a:hover]:text-[#D06F1A] [&>li]:border-b [&>li]:border-[#d6d6d6]"
              ref={listRef}
            >
              <li className="text-[#4A4F55]" onClick={() => closePop(newSubmissionRef.current?.promotionRoute)}>
                <a type="button">Start a new Application</a>
              </li>
              <li className="text-[#4A4F55]" onClick={() => closePop(() => navigate(PageRoutes.SUBMISSIONS))}>
                <a type="button">View my Applications</a>
              </li>
              {promotion?.id && (
                <li
                  className="text-[#4A4F55]"
                  onClick={() => closePop(() => navigate(PageRoutes.promotionDetail(promotion.id)))}
                >
                  <a type="button">Continue my Application</a>
                </li>
              )}
              <li className="text-[#4A4F55]" onClick={() => closePop(logout)}>
                <a type="button">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      )) ||
        null}
      <NewSubmissionConfirmationModal navigate={navigate} ref={newSubmissionRef} />
    </div>
  );
}
