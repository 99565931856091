import * as React from "react";
import { cn } from "../../lib/utils";

interface ContainerLayoutProps {
  children: React.ReactNode;
  className?: string | undefined;
  breadcrumb?: Array<Record<string, string>>;
}
interface SidebarLayoutProps {
  children: React.ReactNode;
  className?: string | undefined;
  bordered?: boolean;
}
interface ContentLayoutProps {
  children: React.ReactNode;
  className?: string | undefined;
}

export const ContainerLayout = ({ children, className, breadcrumb }: ContainerLayoutProps) => {
  return (
    <section className={cn("container px-[24px] md:px-[30px] lg:px-[45px]", className)}>
      <div className="flex flex-col-reverse md:flex-row">{children}</div>
      {/* <Cookies/> */}
    </section>
  );
};

export const SidebarLayout = ({ children, className, bordered }: SidebarLayoutProps) => {
  const border = bordered ? "md:border-r md:border-solid md:border-[#d6d6d6]" : "";
  return <div className={cn(`basis-1/4 ${border}`, className)}>{children}</div>;
};
export const ContentLayout = ({ children, className }: ContentLayoutProps) => {
  return <div className={cn("basis-3/4 min-h-0 min-w-0", className)}>{children}</div>;
};
