import React from "react";
import { Link } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";

export default function Fotter() {
  return (
    <section>
      <div className="bg-secondary">
        <div className="container px-[24px] md:px-[30px] lg:px-[45px] py-[40px]">
          <div className="container px-0 flex flex-col lg:flex-row justify-between">
            <div className="flex md:flex-col md:justify-center  text-center mb-[20px] lg:mb-0 ">
              <p className="font-normal text-[14px] text-[#fff]">&copy; 2024 The Beer Store</p>
            </div>
            <div className="flex flex-row justify-center">
              <ul className="[&_li_a:hover]:text-[#d06f1a] [&_li]:px-[20px] [&_li]:font-normal [&_li]:text-[14px] [&_li]:text-[#fff] [&_li]:gap-5 flex flex-wrap justify-center md:justify-center">
                <li>
                  <a className="hover:text-[#B95804]" href="https://thebeerstore.ca/" target="_blank">
                    TheBeerStore.ca
                  </a>
                </li>
                <li>
                <a className="hover:text-[#B95804]" href="https://cdnecommnp.thebeerstore.ca/assets/Brewer-portal/images/2024_Moments&Occasions_CalendarNew_CycleDate.pdf?si=read-policy&spr=https&sv=2022-11-02&sr=c&sig=NWlm3QNpx7LwEko2J1tPV%2Fnjbxw9rAGNyPlx%2B3MIIe8%3D" target="_blank">
                  Occasions Calendar
                  </a>
                </li>
                <li>
                  <Link className="hover:text-[#B95804]" to={PageRoutes.FAQ}>FAQ</Link>
                </li>
                <li>
                  <Link className="hover:text-[#B95804]" to={PageRoutes.CONTACT_US}>Contact Us</Link>
                </li>
                <li>
                  <Link className="hover:text-[#B95804]" to={PageRoutes.TERMS_CONDITION}>Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
