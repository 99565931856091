import { Outlet, Navigate } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";
import { useAuth } from "src/context/authContext";
import { usePromotionStore } from "src/store/promotion";

const IsPromotionalUser = ({ isNew = false }: { isNew?: boolean }) => {
  const { user } = useAuth();
  const { promotion } = usePromotionStore();
  return (!isNew && user?.name && !promotion.id) || (isNew && user?.name && promotion.id) ? (
    <Navigate to={PageRoutes.HOME} />
  ) : (
    <Outlet />
  );
};

export default IsPromotionalUser;
