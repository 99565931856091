import React, { useState, forwardRef, useRef, useEffect } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import Arrow from "../assets/images/downArrow.svg";
interface OptionsProps {
  optionname?: string | any;
  optionval?: string | any;
}

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: string) => void;
  defaultValue?: string;
  selectAriaLabel?: string;
  options: Array<OptionsProps>;
  error?: boolean;
  scroll?: boolean;
}

// eslint-disable-next-line react/display-name
const DropdownSelect = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { options, onChange, className, selectAriaLabel, defaultValue = "", error, scroll = false, value, ...rest },
    ref
  ) => {
    const customRef = React.useRef<any>();
    const [toggle, setToggle] = useState(false);
    const [active, setActive] = useState(
      (defaultValue && options?.find((item) => item.optionval == defaultValue)?.optionname) || defaultValue || ""
    );

    useEffect(() => {
      if (!value) {
        setActive(defaultValue);
      }
    }, [value]);

    const onHandleKeyDown = (e: { key: any; preventDefault: () => void }) => {
      switch (e.key) {
        case " ":
        case "Enter":
          e.preventDefault();
          setToggle(!toggle);
          break;
        default:
          break;
      }
    };
    const onItemHandleKeyDown = (e: { key: any; preventDefault: () => void }, value: string) => {
      switch (e.key) {
        case " ":
        case "Enter":
          e.preventDefault();
          const getLabel = options?.find((item) => item.optionval == value)?.optionname;
          setActive(getLabel);
          onChange?.(value);
          setToggle(false);
          break;
        default:
          break;
      }
    };
    const handlerOnClick = (value: string) => {
      const getLabel = options?.find((item) => item.optionval == value)?.optionname;
      setActive(getLabel);
      onChange?.(value);
      setToggle(false);
    };

    const handlerToggle = () => {
      setToggle(!toggle);
      // listRef.current?.focus();
    };
    useOnClickOutside(customRef, () => setToggle(false));

    const listRef = useRef<HTMLUListElement>(null);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>, index: number) => {
      try {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.preventDefault();
          const direction = e.key === "ArrowDown" ? 1 : -1;
          const nextIndex = (index + direction + options.length) % options.length;
          const nextElement = listRef.current?.querySelectorAll("li")[nextIndex] as HTMLLIElement | null;
          nextElement && nextElement.focus();
        }
        if (e.key === "Tab") {
          const isLastItem = index === options.length - 1;
          if (!e.shiftKey && isLastItem) {
            setToggle(false);
          } else if (e.shiftKey && index === 0) {
            setToggle(false);
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    return (
      <div ref={customRef} className={className} {...rest} id="">
        <button
          tabIndex={0}
          onKeyDown={(e) => onHandleKeyDown(e)}
          onClick={handlerToggle}
          aria-expanded={toggle ? true : false}
          aria-label={selectAriaLabel}
          type="button"
          className={`${
            error ? "border-[#bf1332]" : "border-[#d6d6d6]"
          }  w-full text-left border rounded-full  text-[#4A4F55] text-[14px] leading-normal relative ${
            active ? "pt-[15px] pb-[12px] px-[20px]" : "pb-[20px] pt-[20px]"
          }`}
        >
          {active}
          <span
            className={`icon-arrow w-[12px] text-[7px] text-[#000] absolute top-1/2 -translate-y-1/2 right-[20px] ${
              toggle ? `rotate-180` : ""
            }`}
          >
            <img src={Arrow} alt={"arrwoDown"} height={6} width={11} />
          </span>
          <input className="sr-only" ref={ref} {...rest} tabIndex={-1} />
        </button>

        <ul
          className={`itemUl shadow-[0_4px_30px_0px_rgba(0,0,0,0.07)] rounded-[5px] z-10 bg-white py-[10px] absolute lef-0 top-full w-full ${
            scroll ? "overflow-auto max-h-[200px] scroll-bar-grey custom-scrollbar" : " "
          } ${toggle ? `block` : `hidden`}`}
          ref={listRef}
        >
          {options.map((item, key) => (
            <li
              tabIndex={0}
              key={key}
              data-value={item?.optionval}
              onClick={() => handlerOnClick(item?.optionval)}
              onKeyDown={(e) => {
                handleKeyDown(e, key);
                onItemHandleKeyDown(e, item?.optionval);
              }}
              className={`selectItem text-[14px] text-[#4A4F55] py-[5px] px-[20px] hover:bg-[#f4f4f4] hover:text-[#D06F1A] cursor-pointer ${
                active == item?.optionname && `!text-[#D06F1A]`
              }`}
            >
              {item?.optionname}
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

export default DropdownSelect;
