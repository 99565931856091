export class SuccessMessages {
  static readonly CONTACT_US_SUCCESS = "Thank you for your message. It has been sent.";
  static readonly DEFAULT_SUCCESS_TITLE = "Successful";
  static readonly PROGRAM_ADDED_SUCCESS = "Program has been successfully added.";
  static readonly PROGRAM_UPDATED_SUCCESS = "Program has been successfully updated.";
  static readonly PROMOTION_ADD_SUCCESS = "Promotion has been successfully added.";
  static readonly PROMOTION_UPDATE_SUCCESS = "Promotion has been successfully updated.";
  static readonly FORGOT_PASS_SUCCESS =
    "An email has been sent if the requested email or username is valid. Please check your inbox.";
  static readonly FORGOT_PASS_ERROR = "Oops! Something went wrong. Please try again.";
}
