import React, { useState, forwardRef } from "react";
import Check from "../../assets/images/check.svg";
import { cn } from "src/lib/utils";

interface RadioButtonProps {
  label: React.ReactNode;
  selected: boolean;
  id: string;
  onSelect: () => void;
  className?: string | undefined;
  [key: string]: any
}

// eslint-disable-next-line react/display-name
const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const { label, id, onSelect, selected, className, ...rest } = props;
  return (
    <label
      htmlFor={id}
      className="flex gap-[8px] items-start text-sm  font-normal leading-6  peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    >
      <div className="relative mt-[2px] h-[18px] w-[18px] rounded-[30px] border-[1px] border-[#d6d6d6]">
        <input
          {...rest}
          className={cn(`appearance-none centered rounded-[30px] h-[12px] w-[12px] ${
            selected ? "!bg-[#000]" : ""
          } checked:border-[1px]  checked:bg-[#000] checked:border-transparent focus:outline-none`, className)}
          type="radio"
          checked={selected}
          id={id}
          name={id}
          onChange={onSelect}
          ref={ref}
        />
      </div>

      {label}
    </label>
  );
});

export default RadioButton;
