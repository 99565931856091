import { COOKIES_KEYS } from "src/constants";
import cookies from "js-cookie";
import config from "src/config";

type AccessTokenHandlerOperations = "GET" | "SET" | "REMOVE";

export const accessTokenHandler = (
  operation: AccessTokenHandlerOperations,
  token?: string,
  expire?: number
): string | null | void => {
  switch (operation) {
    case "GET":
      return cookies.get(COOKIES_KEYS.ACCESS_TOKEN);
    case "SET":
      cookies.set(COOKIES_KEYS.ACCESS_TOKEN, token!, {
        expires: new Date(expire || (Date.now() / 1000 + +config.defaultCookieExpiration!) * 1000),
      });
      break;
    case "REMOVE":
      cookies.remove(COOKIES_KEYS.ACCESS_TOKEN);
      break;
    default:
      break;
  }
};

export const refreshTokenHandler = (
  operation: AccessTokenHandlerOperations,
  token?: string,
  expire?: number
): string | null | void => {
  switch (operation) {
    case "GET":
      return cookies.get(COOKIES_KEYS.REFRESH_TOKEN);
    case "SET":
      cookies.set(COOKIES_KEYS.REFRESH_TOKEN, token!, {
        expires: new Date(expire || (Date.now() / 1000 + +config.defaultCookieExpiration!) * 1000),
      });
      break;
    case "REMOVE":
      cookies.remove(COOKIES_KEYS.REFRESH_TOKEN);
      break;
    default:
      break;
  }
};
