import { FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import Check from "../../assets/images/check.svg";
import RadioButton from "../../components/radioCheckbox";
import { CycleData, ICycle } from "src/types/program.type";
import { memo } from "react";
import { UseFormReturn } from "react-hook-form";
import z from "zod"
import { programSchema } from "src/lib/validationSchema/validation";

interface CycleProps {
  form: UseFormReturn<z.infer<typeof programSchema>, any, undefined>;
  cycles: ICycle[];
  selectedCycleData: ICycle;
  onTabChange: (val: any) => void;
}
function Cycle({ form, cycles, onTabChange, selectedCycleData }: CycleProps) {
  return (
    <>
      {cycles.length ? (
        <Tabs
          className="gap-[32px]"
          value={selectedCycleData.name}
          defaultValue={selectedCycleData.name}
          onValueChange={onTabChange}
        >
          <TabsList className="flex flex-row [&_.trigger]:relative [&_.trigger>div]:absolute [&_.trigger>div]:right-[50%] [&_.trigger>div]:top-[-10px] [&_.trigger]:px-[10px] [&_.trigger]:py-[9px] [&_.trigger]:border [&_.trigger]:border-solid [&_.trigger]:rounded-[5px] gap-[10px] [&_.trigger]:basis-1/3 justify-start">
            {cycles?.map((item, idx) => (
              <TabsTrigger
                key={idx}
                className="trigger data-[state=active]:border-[#534040] w-[93%] xs:w-full max-w-[133px] hover:bg-[#f4f4f4] hover:[&>p]:text-[#D06F1A]"
                value={item?.name}
              >
                <div className="rounded-full bg-[#000] -translate-x-1/2 left-1/2 group-data-[state=inactive]:hidden h-[18px] w-[18px] flex items-center justify-center">
                  <img src={Check} height={8} width={12} alt="green-check" className="m-auto" />
                </div>
                <p className="w-full">{item?.name}</p>
              </TabsTrigger>
            ))}
          </TabsList>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[30px] gap-y-[10px] mt-[30px]">
            {selectedCycleData.data?.map((item: CycleData, idx) => (
              <FormField
                control={form.control}
                name="cycle"
                key={item?.id}
                render={({ field }) => {
                  return (
                    <FormItem
                      onClick={(event) => {
                        event?.stopPropagation();
                        if (item?.isAvailable && item?.programCycleId?.toString() != field.value) {
                          form.setValue("cycle", String(item?.programCycleId!));
                          form.setValue("type", item?.cycleType);
                          form.resetField("zone");
                          form.setValue("cycleIndex", idx);
                          form.clearErrors("cycle");
                        }
                      }}
                      className={`flex flex-row mdtab:flex-col xl-1365:flex-row items-start basis-full border border-solid rounded-[10px] md:items-start xl-1365:items-center ${
                        field.value === item?.programCycleId?.toString() ? "bg-[#f4f4f4]" : "bg-[#fff]"
                      } [&_span]:w-full [&_label]:w-full  [&_label]:lg-1200:text-[16px] [&_label]:mdtab:text-[12px] [&_label]:3xs:text-[16px] [&_label]:text-[12px] [&_label]:capitalize ${!item?.isAvailable ? '[&_label]:font-GothamMedium':'[&_label]:font-GothamBold'}
                       ${!item?.isAvailable ? "bg-[#F9F9F9] cursor-default opacity-40" : "[&_label]:hover:text-[#d06f1a] [&_label]:cursor-pointer [&_input]:cursor-pointer cursor-pointer"} p-[20px] justify-between`}
                    >
                      <FormControl>
                        <>
                          <div>
                            <RadioButton
                              {...field}
                              selected={field.value == item?.programCycleId?.toString()}
                              id={String(item?.programCycleId || item?.id)}
                              label={item?.label}
                              disabled={!item?.isAvailable}
                            />
                          </div>
                          <div className="flex text-[14px] md:!mt-0 leading-[24px] items-center font-sans">
                            <span>{item?.dateRange}</span>
                          </div>
                        </>
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
            ))}
          </div>
        </Tabs>
      ) : null}
    </>
  );
}
export default memo(Cycle);
