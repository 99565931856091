import { SuccessMessages } from "src/constants/successMessages";
import { create } from "zustand";

export interface Alert {
  isOpen?: boolean;
  message: string;
  title?: string;
  variant: "destructive" | "successful" | "alert" | "default" | "successfully";
}
interface AlertStore {
  alert: Alert;
  tId: any;
  setAlert: (data: Alert) => void;
  closeAlert: () => void;
}

export const useAlertStore = create<AlertStore>()((set) => ({
  alert: {
    isOpen: false,
    variant: "default",
    message: "",
    title: "",
  },
  tId: "",
  setAlert: (data) => {
    // reset
    const tId = setTimeout(() => {
      set((state) => ({
        alert: { ...state, isOpen: false, variant: "default", message: "", title: "" },
        tId: "",
      }));
    }, 7000);

    set((state) => {
      if (state.tId) {
        clearTimeout(state.tId);
      }
      return {
        alert: {
          ...state,
          isOpen: data.isOpen || true,
          variant: data.variant,
          message: data.message,
          title: data?.title ?? SuccessMessages.DEFAULT_SUCCESS_TITLE,
        },
        tId,
      };
    });
  },
  closeAlert: () => {
    set((state) => {
      if (state.tId) {
        clearTimeout(state.tId);
      }
      return {
        alert: {
          isOpen: false,
          variant: "default",
          message: "",
          title: "",
        },
        tId: "",
      };
    });
  },
}));
