import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import Contactbg from "../../assets/images/contactusbg.jpg";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { contactUsSchema } from "../../lib/validationSchema/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DropdownSelect from "../../components/dropDown";
import { DEFAULT_SERVICES_TEXT, SERVICES_FIELD_OPTIONS } from "../../constants";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "src/config";
import { exceptionHandler } from "src/utils/helper";
import { useAlertStore } from "src/store/alert";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { SuccessMessages } from "src/constants/successMessages";

export default function ContactUs() {
  const form = useForm<z.infer<typeof contactUsSchema>>({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      // contactOption: "",
      comment: "",
      recaptcha: "",
    },
    mode: "onChange",
  });
  const { setAlert } = useAlertStore();
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onChange = useCallback((val: string) => {
    form.setValue("recaptcha", val);
  }, []);

  const resetForm = async () => {
    form.reset();
    const token = await executeRecaptcha!();
    form.setValue("recaptcha", token);
  };

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      setLoading(true);
      const res = await api.contactUs.add({ ...data });
      if (res.status === 201 || res.status === 200) {
        resetForm();
        setAlert({
          message: res.data?.message || SuccessMessages.CONTACT_US_SUCCESS,
          variant: "successful",
        });
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setLoading(false);
    }
  });

  return (
    <FullPageTemplate desktopBg={Contactbg} mobileBg={Contactbg} bannerTitle={"Contact Us"}>
      <GoogleReCaptcha onVerify={onChange} />
      <div className="flex flex-col gap-[30px] max-w-[944px] m-auto">
        <div className={"flex flex-col"}>
          <h2 className="text-[20px] font-Gotham font-[700] 3sx:text-[24px] xs:text-[32px] md:text-[32px] lg:text-[32px] xl:text-[32px]  leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[42px] xl:leading-[42px] text-[#000] m-0 flex items-center mb-5">
            {"We’d like to hear from you!"}
          </h2>
          <p className="font-sans text-[14px] leading-[20px]">
            If you have questions about a program, opportunity, or want to collaborate, send us a note with some insight into your enquiry and we’ll connect back with you.
          </p>
        </div>
        <Form {...form}>
          <form
            className="flex flex-col [&_.error-msg]:text-[12px] [&_.error-msg]:text-[#bf1332] 
        [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.form-label]:text-[14px] 
        [&_.form-label]:font-normal  [&_.form-label]:text-[#4A4F55] [&_.form-label]:font-sans [&_.form-label]:leading-5 [&_.form-itm]:mb-5 [&_.form-checkbox]:my-[10px] [&_.reply-input>input]:placeholder:text-[#4A4F55] [&_.reply-input>input]:placeholder:text-[14px] [&_.form-itm>button>span]:text-[#4A4F55] [&_.form-itm>textarea]:placeholder:text-[#4A4F55] [&_.reply-input>input]:text-[#4A4F55] [&_.form-itm>textarea]:text-[#4A4F55]"
            onSubmit={onSubmit}
          >
            <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
              <div className="basis-full md:basis-1/2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="form-itm">
                      <FormLabel className="form-label" htmlFor="firstName">
                        First Name
                        <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                      </FormLabel>
                      <Input
                        id="firstName"
                        className={`reply-input ${form.formState.errors.firstName ? "border-[#bf1332]" : "border-[#d6d6d6]"
                          }`}
                        maxLength={25}
                        aria-label="please enter your name"
                        placeholder={"Enter your name"}
                        {...field}
                      />
                      <FormMessage className="error-msg" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="basis-full md:basis-1/2">
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="form-itm">
                      <FormLabel className="form-label" htmlFor="lastName">
                        Last Name
                        <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                      </FormLabel>
                      <Input
                        id="lastName"
                        maxLength={50}
                        className={`reply-input ${form.formState.errors.lastName ? "border-[#bf1332]" : "border-[#d6d6d6]"
                          }`}
                        aria-label="please enter your email"
                        placeholder={"Enter your name"}
                        {...field}
                      />
                      <FormMessage className="error-msg" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
              <div className="basis-full md:basis-[48.333%]">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="form-itm">
                      <FormLabel className="form-label" htmlFor="email">
                        Email
                        <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                      </FormLabel>
                      <Input
                        id="email"
                        maxLength={50}
                        className={`reply-input ${form.formState.errors.email ? "border-[#bf1332]" : "border-[#d6d6d6]"
                          }`}
                        aria-label="please enter your email"
                        placeholder={"Enter your email address"}
                        {...field}
                      />
                      <FormMessage className="error-msg" />
                    </FormItem>
                  )}
                />
              </div>

              {/* <div className="basis-full md:basis-1/2">
                <FormField
                  control={form.control}
                  name="contactOption"
                  render={({ field }) => (
                    <FormItem
                      className={`form-itm  ${
                        form.formState.errors.contactOption
                          ? "[&>button]:border-[#bf1332]"
                          : "[&>button]:border-[#d6d6d6]"
                      } `}
                    >
                      <FormLabel className="form-label" htmlFor="contactOption">
                        Select Option
                        <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                      </FormLabel>
                      <DropdownSelect
                        {...field}
                        id="contactOption"
                        onChange={field.onChange}
                        value={field.value}
                        options={SERVICES_FIELD_OPTIONS}
                        defaultValue={DEFAULT_SERVICES_TEXT}
                        className="min-w-[156px] relative"
                        error={form?.formState?.errors?.contactOption ? true : false}
                      />
                      <FormMessage className="error-msg" />
                    </FormItem>
                  )}
                />
              </div> */}
            </div>

            <div className="basis-full">
              <FormField
                control={form.control}
                name="comment"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="comment">
                      Comments
                      <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                    </FormLabel>
                    <Textarea
                      id="comment"
                      maxLength={300}
                      className={`py-[15px] px-[20px] rounded-[10px] resize-none h-full min-h-[120px] ${form.formState.errors.comment ? "border-[#bf1332]" : "border-[#d6d6d6]"
                        }`}
                      {...field}
                      placeholder={"Enter Comments"}
                      aria-label="please enter your comment"
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
            <div className="contact-btn">
              <Button
                type="submit"
                className="uppercase h-[50px] min-w-[124px] text-[16px] font-GothamBold leading-5 rounded-[200px] px-[30px] md:py-[15px] mt-[12px] md:mt-[10px]"
                disabled={loading}
              >
                {loading ? "Please Wait..." : "Submit"}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </FullPageTemplate>
  );
}
