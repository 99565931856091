import { useCallback, useEffect, useState } from "react";
import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { SidebarLayout } from "../../modules/programsLayout";
import { Button } from "../../components/ui/button";
import CloseSvg from "../../assets/images/sidebarclose.svg";
import Filter from "../../assets/images/filters.svg";
import InfoCard from "../../components/infoCard";
import Dummy from "../../assets/images/dummy.png";
import ProductSidebarFilters from "../../modules/sidebarFilters";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ICategory } from "src/types/category.type";
import { IProgram } from "src/types/program.type";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { exceptionHandler } from "src/utils/helper";
import { useAlertStore } from "src/store/alert";
import { useDebounce } from "src/hooks/useDebounce";
import Pagination from "src/modules/pagination";
import PageRoutes from "src/constants/pageRoutes";
import MugLoaderIcon from "../../assets/images/TBS_2.0_Loader.gif";
import NoResults from "./components/noResults";
import Steps from "src/components/steps";
import DisclaimerText from "src/components/disclaimerText";

const QUERY_KEY = {
  CATEGORY: "category",
  SEARCH: "q",
  TAKE: "take",
  SKIP: "skip",
  PAGE: "page",
};
const TAKE = 12;

const breadcrumb = [
  {
    id: "1",
    key: "Home",
    value: PageRoutes.HOME,
  },
  {
    id: "2",
    key: "Program",
    value: PageRoutes.PROGRAM,
  },
];

export default function Programs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showSidebarFilters, setShowSidebarFilters] = useState(false);
  const mediaScreen = useMediaQuery({ "max-width": "819.98px" });
  const [categories, setCategories] = useState<Array<ICategory>>([]);
  const [programs, setPrograms] = useState<Array<IProgram>>([]);
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlertStore();
  const debouncedSearch = searchParams.get(QUERY_KEY.SEARCH) || "";
  const activeCategoryFilters = searchParams.get(QUERY_KEY.CATEGORY) || "";
  const activePage = searchParams.get(QUERY_KEY.PAGE) || 1;
  const [search, setSearch] = useState(debouncedSearch);
  const [totalPrograms, setTotalPrograms] = useState(0);
  const showSidebarStyle = "left-0 visible delay-600 duration-700 z-[1020]";
  const fixedlayoutStyle = "fixed bg-white right-0 bottom-0 top-0 w-full h-full overflow-auto invisible";
  const ToggleSidebarLayout = () => {
    setShowSidebarFilters(true);
  };
  const pages = Math.ceil(totalPrograms / TAKE);

  const debounceHandler = useDebounce((val: string) => {
    const param = new URLSearchParams(window.location.search);
    param.set(QUERY_KEY.SEARCH, val);
    param.set(QUERY_KEY.PAGE, "1");
    setSearchParams(param);
  }, 600);

  const handleSearchChange = useCallback((val: string) => {
    setSearch(val);
    debounceHandler(val);
  }, []);

  const handleFilter = useCallback(
    (filter: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      const val = activeCategoryFilters.includes(`${filter},`)
        ? activeCategoryFilters.replace(`${filter},`, "")
        : activeCategoryFilters.concat(`${filter},`);
      searchParams.set(QUERY_KEY.CATEGORY, val);
      searchParams.set(QUERY_KEY.PAGE, "1");
      setSearchParams(searchParams);
    },
    [searchParams, activeCategoryFilters]
  );

  const onPageChange = useCallback((val: string | number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(QUERY_KEY.PAGE, (+val + 1).toString());
    setSearchParams(searchParams);
  }, []);

  function clearFilters() {
    setSearchParams({});
    setSearch("");
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
        const params = new URLSearchParams(window.location.search);
        params.set(QUERY_KEY.TAKE, String(TAKE));
        params.set(QUERY_KEY.SKIP, ((+activePage - 1) * TAKE).toString());
        params.delete(QUERY_KEY.PAGE);
        const [categoryRes, programsRes] = await Promise.all([
          categories.length || api.category.list(),
          api.program.list(`?${params.toString()}`),
        ]);
        if ((typeof categoryRes != "number" && categoryRes.status != 200) || programsRes.status != 200) {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
        if (typeof categoryRes != "number" && Array.isArray(categoryRes?.data?.data)) {
          setCategories(categoryRes?.data?.data);
        }
        if (Array.isArray(programsRes.data.data)) {
          setPrograms(programsRes.data.data);
          setTotalPrograms(programsRes.data.count);
        }
      } catch (error) {
        exceptionHandler(error, setAlert, "destructive");
      } finally {
        setLoading(false);
      }
    })();
  }, [searchParams.toString()]);

  return (
    <FullPageTemplate
      breadcrumbList={breadcrumb}
      // desktopBg={Program}
      // mobileBg={Program}
      // bannerTitle={"Advertising & Promotional Opportunities Brewer Portal"}
      // className="[]"
    >
      <>
        <div
          // className="flex flex-col md:flex-row relative pb-[30px] xs:pb-[48px] md:pb-[50px] lg:pb-[60p] xxl:pb-[70px] 2xl:pb-[80px] gap-[30px]"
          className="flex flex-col md-820:flex-row relative pb-[0px] xs:pb-[0px] md:pb-[0px] lg:pb-[0px] xxl:pb-[0px] 2xl:pb-[0px] gap-[30px]"
        >
          <div
            style={{
              ...(showSidebarFilters && {
                visibility: showSidebarFilters ? "visible" : "hidden",
              }),
            }}
            className={`
           ${mediaScreen && fixedlayoutStyle}
            md-820:h-auto
            py-[30px]
            px-[24px]
            md-820:p-0
            md-820:left-0
            md-820:relative
            md-820:transition-none
            md-820:visible
            flex-1
            md-820:z-auto
            md-820:basis-2/6
            lg:basis-1/4
            ${showSidebarFilters && showSidebarStyle} 
            ${!showSidebarFilters && mediaScreen && "left-full z-[-1] delay-600 duration-900 "}
           `}
          >
            {mediaScreen && (
              <div
                className="absolute justify-end right-[24px] top-[30px] flex cursor-pointer"
                onClick={() => setShowSidebarFilters(false)}
              >
                <img src={CloseSvg} width={20} height={26} alt="close" />
              </div>
            )}

            <SidebarLayout bordered={false}>
              <div className="pb-5 border-b-[1px]">
                <h2 className="text-[18px] text-left  leading-[28px] font-GothamBold">FILTERS</h2>
              </div>
              <div className="px-0 pb-[30px] md:pb-[25px] md:pl-0">
                {/* <Button onClick={clearFilter}>Clear Filter</Button> */}
                <ProductSidebarFilters
                  categories={categories}
                  handleFilter={handleFilter}
                  onChange={handleSearchChange}
                  search={search}
                  activeCategoryFilters={activeCategoryFilters}
                />
              </div>
            </SidebarLayout>
          </div>
          <div className="basis-full md:basis-4/6 lg:basis-3/4">
          <div className="flex flex-col gap-[20px]">
            <Steps />
            <div className="flex flex-col px-0 ">
              <DisclaimerText content="Attach a program to your promotion." />
              {programs && programs?.length ? (
                <p className="text-[14px] leading-[24px] font-sans text-[#4A4F55]">Showing {totalPrograms} Result(s)</p>
              ) : null}
              <div className="flex flex-row mb-[30px] justify-between items-center beers-filter">
                {mediaScreen && (
                  <Button
                    onClick={ToggleSidebarLayout}
                    variant="outline"
                    className="border-[1px] border-[#D6D6D6] rounded-full h-[40px] w-[40px] p-[10px] ml-auto xs:h-[50px] xs:w-[50px]"
                  >
                    <img src={Filter} alt="filter" width={14} height={14} className={"mr-[0px]"} />
                    {/* Filters */}
                  </Button>
                )}
              </div>
              <>
                {loading ? (
                  <div className="flex w-full justify-center mt-10">
                    {/* <h2>Loading...</h2> */}

                    <img src={MugLoaderIcon} width={200} height={200} alt="DownloadIcon" />
                  </div>
                ) : programs.length ? (
                  <div className="main-info grid  grid-cols-1 md:grid-cols-2   xl:grid-cols-3  gap-y-[30px]  gap-x-[20px] md:gap-[30px]">
                    {programs.map((item, idx) => (
                      <div key={item.id} className="basis-full md:basis-1/3">
                        <div className="main-info md:py-0">
                          <InfoCard
                            onClickButton={() => navigate(PageRoutes.programDetail(item.id))}
                            badgeText={item?.category}
                            from="home"
                            title={item?.name}
                            // description={item?.description}
                            image={{
                              url: item.image || Dummy,
                              alt: item.name,
                              width: 355,
                              height: 232,
                            }}
                            className={`equal-h relative flex-col flex shadow-none rounded-[10px] border-[1px] overflow-hidden [&_.info-content]:py-[20px] md:[&_.info-content]:!p-5  [&_.info-content]:!px-0 [&_.info-content>div>h2]:mb-[20px] [&_.info-content>div>h2]:!leading-[24px] md:[&_.info-content>div>h2]:!leading-[30px] [&>div>div>a]:font-bold [&>div>div>a]:uppercase [&>div>img]:rounded-[10px] [&_.info-content>div>h2]:text-[16px] md:[&_.info-content>div>h2]:text-[18px] [&_.info-content>div>h2]:font-medium md:[&_.info-content>div>h2]:font-bold
                            [&_.info-header]:relative
                            [&_.info-content]:p-[20px]
                            [&_.info-badge]:absolute
                            [&_.info-badge]:top-[20px]
                            [&_.info-badge]:right-[20px]
                            [&_.info-badge]:ml-[20px]
                            [&_.info-headerpage]:overflow-hidden
                            [&_.info-header]:overflow-hidden
                            md:[&_.info-header]:max-h-[150px]
                            md:[&_.info-image]:min-h-[150px]
                            md-820:[&_.info-header]:!max-h-[160px]
                            md-820:[&_.info-image]:!min-h-[160px]
                            mdtab:[&_.info-header]:!max-h-[200px]
                            mdtab:[&_.info-image]:!min-h-[200px]  
                            lg:[&_.info-header]:!max-h-[232px]
                            lg:[&_.info-image]:!min-h-[232px]
                            xl:[&_.info-header]:!max-h-[190px]
                            xl:[&_.info-image]:!min-h-[190px]
                            xl-1440:[&_.info-header]:!max-h-[232px]
                            xl-1440:[&_.info-image]:!min-h-[232px]
                            [&_.info-description]:text-[14px]
                            [&_.info-description]:min-h-[40px]
                            [&_.info-description]:!h-auto
                            [&_.info-description]:text-ellipsis
                            [&_.info-description]:line-clamp-2
                            [&_.info-description]:leading-[20px]
                            [&_.info-description]:font-sans
                            [&_.info-badge]:h-auto
                            [&_.info-badge]:min-h-[26px]
                            [&_.info-badge]:py-[6px]
                            [&_.info-badge]:px-[12px]
                            [&_.info-badge>span]:text-center
                            lg:[&_.info-badge>span]:text-[14px]
                            [&_.info-badge>span]:leading-[18px]
                            [&_.info-badge>span]:font-GothamMedium
                            [&_.info-badge>span]:text-[12px]
                            [&_.info-title]:font-GothamMedium
                            [&>div>img]:absolute [&>div>img]:top-0 [&>div>img]:left-0 [&>div>img]:object-contain [&_img]:max-h-[232px] [&_img]:border-b-[1px] [&_img]:border-[#D6D6D6]
                            [&_.info-title]:max-w-full 3xs:[&_.info-title]:max-w-[250px] md:[&_.info-title]:max-w-full [&_.info-title]:leading-[28px] [&_.info-footer]:pt-[20px] [&_button]:font-GothamMedium [&_button]:text-[10px] mdtab:[&_button]:text-[12px] lg:[&_button]:text-[14px] [&_button]:leading-[16px] [&_button]:px-[20px] [&_button]:py-[10px] [&_button]:!w-auto [&_button]:h-auto [&_button]:max-h-[40px] [&_.topBadge>span]:text-[8px] [&_.topBadge>span]:lg:text-[12px] [&_.topBadge>span]:leading-[14px] [&_.topBadge>span]:lg:leading-[18px]`}
                            buttonLink={PageRoutes.programDetail(item.id)}
                            buttonText={"APPLY FOR PROGRAM"}
                            buttonAriaLabel={"APPLY FOR PROGRAM"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex w-full justify-center">
                    <NoResults clearFilters={clearFilters} />
                  </div>
                )}
              </>
            </div>
            </div>
            {pages > 1 && !loading ? (
              <div className="flex justify-center items-center w-[100%] mt-[30px] md:mt-[40px]">
                <div className="flex items-center gap-[10px] md:gap-[20px]">
                  <Pagination
                    pages={pages}
                    activePage={((!isNaN(+activePage) && +activePage) || 1) - 1}
                    handler={onPageChange}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    </FullPageTemplate>
  );
}
