import { ButtonHTMLAttributes } from "react";
import prevBtn from "src/assets/images/previous.svg";
import nextBtn from "src/assets/images/nextbtn.svg";
import { cn } from "../lib/utils";
interface PaginationProps {
  pages: number;
  activePage: number;
  handler: (val: number) => void;
}

export default function Pagination({ pages, activePage, handler }: PaginationProps) {
  return (
    <>
      {pages > 1 && (
        <div className="flex justify-center items-center w-[100%] mt-[0px] md:mt-[0px]">
          <div className="flex items-center gap-[10px] md:gap-[20px]">
            <PageButton
              className={cn(`paginationArrow`, activePage == 0 ? "opacity-[0.5] cursor-auto" : "hover:cursor-pointer")}
              label={prevBtn}
              disabled={activePage == 0}
              onClick={() => handler(activePage - 1)}
            />
            <ul className="flex gap-[15px] md:gap-[30px]">
              <PaginationCustom pageNum={pages} onPageChange={handler} activePage={activePage + 1} />
            </ul>
            <PageButton
              className={cn(
                `paginationArrow`,
                activePage + 1 == pages ? "opacity-[0.5] cursor-auto" : "hover:cursor-pointer"
              )}
              label={nextBtn}
              disabled={activePage + 1 == pages}
              onClick={() => handler(activePage + 1)}
            />
          </div>
        </div>
      )}
    </>
  );
}

const PaginationCustom = (props: any): any => {
  var { activePage, numberOfPagesNextToActivePage = 1, pageNum } = props;
  let current = activePage,
    last = pageNum,
    delta = numberOfPagesNextToActivePage,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithEllipsis = [],
    l = undefined,
    isEllipsisIncludes = false;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l && i - l !== 1) {
      rangeWithEllipsis.push(
        <li key={isEllipsisIncludes ? -1 : 0} className="pageElli list-none">
          <a className="font-sans text-[16px]">...</a>
        </li>
      );
      isEllipsisIncludes = true;
    }
    rangeWithEllipsis.push(
      <li
        key={i}
        className="ais-Pagination-item [&.cursor-diabled]:cursor-default cursor-pointer flex items-center justify-center ais-Pagination-item--page ais-Pagination-item--selected [&[data-state=true]]:font-bold [&[data-state=true]]:text-[#D06F1A] hover:cursor-pointer list-none text-[#4A4F55]"
        data-state={activePage == i}
        onClick={(e) => {
          e.preventDefault();
          props.onPageChange(i - 1);
        }}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            props.onPageChange(i - 1);
          }
        }}
      >
        <a className="font-sans text-[16px]" tabIndex={0}>
          {i}
        </a>
      </li>
    );
    l = i;
  }
  return rangeWithEllipsis;
};

const PageButton = (props: ButtonHTMLAttributes<HTMLButtonElement> & { label: string }) => {
  const { label, ...rest } = props;
  return (
    <button {...rest}>
      <img src={label} alt={"Arrow"} />
    </button>
  );
};
