import { FullPageTemplate } from "src/modules/programsLayout/fullPageTemplate";
import Pagination from "src/modules/pagination";
import { useCallback, useEffect, useState } from "react";
import { IPromotion, ProgramStatus } from "src/types/promotion.type";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { exceptionHandler } from "src/utils/helper";
import { useAlertStore } from "src/store/alert";
import { Link, useSearchParams } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";
import ProgramStatusBadge from "src/modules/programStatusBadge";

const breadCrumb = [
  {
    id: "1",
    key: "Home",
    value: PageRoutes.HOME,
  },
  {
    id: "2",
    key: "All Applications",
    value: PageRoutes.SUBMISSIONS,
  },
];

const QUERY_KEY = {
  TAKE: "take",
  SKIP: "skip",
  PAGE: "page",
};
const TAKE = 10;

const Submission = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlertStore();
  const [promotion, setPromotion] = useState<IPromotion[]>([]);
  const [totalPromotion, setTotalPromotion] = useState(0);

  const activePage = searchParams.get(QUERY_KEY.PAGE) || 1;
  const pages = Math.ceil(totalPromotion / TAKE);

  const onPageChange = useCallback((val: string | number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(QUERY_KEY.PAGE, (+val + 1).toString());
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams(window.location.search);
        params.set(QUERY_KEY.TAKE, String(TAKE));
        params.set(QUERY_KEY.SKIP, ((+activePage - 1) * TAKE).toString());
        params.delete(QUERY_KEY.PAGE);
        const res = await api.promotion.list(`${params.toString()}`);
        if (res.status === 200) {
          if (res.data?.data?.length) {
            setPromotion(res.data.data);
            setTotalPromotion(res.data.count);
          }
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        exceptionHandler(error, setAlert, "destructive");
      } finally {
        setLoading(false);
      }
    })();
  }, [activePage]);

  return (
    <>
      <FullPageTemplate loading={loading} breadcrumbList={breadCrumb}>
        <div className="flex flex-col gap-[40px]">
          <div className="flex flex-col gap-[24px] font-GothamBold">
            <h2 className="text-[32px] leading-[42px] text-[#000]">All Applications</h2>
            <p className="text-[14px] leading-[20px] font-sans text-[#4A4F55]">
              Please review the status of your application below. If you have any questions or concerns please contact {" "}
              <Link
                to="mailto:brewerprograms@thebeerstore.ca"
                className="text-[#D06F1A] hover:underline cursor-pointer font-GothamMedium"
              >
                brewerprograms@thebeerstore.ca
              </Link>{" "}
            </p>
          </div>

          <div className="flex flex-col gap-[40px] w-full overflow-x-scroll">
            <table className="flex flex-col w-fit">
              <thead className="w-full border-[1px] rounded-t-[10px] border-b-[2px] border-b-[#000]  bg-[#F4F4F4]">
                <tr className="flex flex-row [&_>th]:p-[24px] [&_>th]:text-[18px] [&_>th]:text-[#000] [&_>th]:leading-[24px] [&_>th]:px-[24px] [&_>th]:py-[23px] [&_>th]:flex [&_>th]:justify-start [&_>th]:font-GothamMedium">
                  <th className="min-w-[201px]">Application Date</th>
                  <th className="w-[200px] lg:min-w-[486px] xl-1536:min-w-[566px] flex shrink-0">Brand Name</th>
                  <th className="min-w-[400px]">Selected Programs</th>
                  <th className="min-w-[120px]">Status</th>
                  <th className="min-w-[140px]">Action</th>
                </tr>
              </thead>

              <tbody className="">
                {promotion.length
                  ? promotion.map((item, index) => {
                      const singleProgram = item?.program?.length === 1;

                      return (
                        <tr
                          key={index}
                          className="w-full flex border-[1px] border-t-[0px] last:rounded-b-[10px] even:bg-[#F4F4F4]"
                        >
                          <td
                            className={`text-[14px] leading-[24px] text-[#4A4F55] font-sans min-w-[201px] border-r-[1px] ${singleProgram ? "px-[24px] py-[12px] flex flex-col justify-center" : "px-[24px] py-[23px]"}`}
                          >
                            {item?.submissionDate}
                          </td>

                          <td
                            className={`text-[14px] leading-[24px] text-[#404040] font-sans w-[200px] flex-wrap lg:min-w-[486px] xl-1536:min-w-[566px] ${singleProgram ? "px-[24px] py-[12px] flex flex-col justify-center" : "px-[24px] py-[23px]"} border-r-[1px] shrink-0`}
                          >
                            {item?.brandName}
                          </td>

                          <td className="min-w-[400px] p-[0px] flex flex-col justify-center">
                            {item?.program?.map((program, id) => {
                              const isLastItem = id == item.program!.length - 1;
                              return (
                                <div
                                  key={id}
                                  className={`text-[14px] min-h-[48px] text-[#404040] font-sans px-[24px] py-[12px]  ${isLastItem ? "last:border-b-0" : "border-b-[1px]"}`}
                                >
                                  {program.cycle.name} {" - "}
                                  {program.name}
                                </div>
                              );
                            })}
                          </td>

                          <td className="min-w-[120px] border-r-[1px] p-[0px] flex flex-col justify-center">
                            {item?.program?.map((program, id) => {
                              const isLastItem = id == item.program!.length - 1;
                              return (
                                <div
                                  key={id}
                                  className={`text-[14px] min-h-[48px] px-[24px] py-[12px]  ${isLastItem ? "last:border-b-0" : "border-b-[1px]"}`}
                                >
                                  <ProgramStatusBadge status={program.status} />
                                </div>
                              );
                            })}
                          </td>

                          <td
                            className={`min-w-[140px] [&>Link]:cursor-pointer text-[14px] leading-[24px] font-GothamMedium text-[#D06F1A] ${singleProgram ? "px-[24px] py-[12px]" : "px-[24px] py-[23px]"}`}
                          >
                            <Link className="hover:underline" to={PageRoutes.promotionDetail(item.id)}>
                              View Details
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {!promotion.length && <div className="text-center mt-[20px]"> No Application found!</div>}
          </div>
          {pages > 1 && !loading ? (
            <div className="flex justify-center items-center w-[100%]">
              <div className="flex items-center gap-[10px] md:gap-[20px]">
                <Pagination
                  pages={pages}
                  activePage={((!isNaN(+activePage) && +activePage) || 1) - 1}
                  handler={onPageChange}
                />
              </div>
            </div>
          ) : null}
        </div>
      </FullPageTemplate>
    </>
  );
};

export default Submission;
