export function useDebounce(callback: Function, wait: number) {
  let timeout: any;

  return function (...args: any) {
    // @ts-ignore
    const context = this;
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callback.apply(context, args);
    }, wait);
  };
}
