import Step_1 from "src/assets/images/Step_1.svg";
import Step_2 from "src/assets/images/Step_2.svg";
import Step_3 from "src/assets/images/Step_3.svg";
import Step_4 from "src/assets/images/Step_4.svg";
import Step_5 from "src/assets/images/Step_5.svg";


export default function Steps(props: any) {
    const {className } = props;
    return (
        
        <div className={`${className} mainWrapper grid grid-row-2 xl-1300:grid-rows-none xl-1300:flex w-full m-auto px-[20px] py-[30px] border border-[#D6D6D6] rounded-[10px]`}>

            <div className={`${className} subWrapper flex xl-1300:basis-[60%]`}>
            <div className="flex flex-col items-center border-r-[1px] pr-[20px] w-full">
                <img
                    className="flex w-[32px] h-[32px] mb-5"
                    src={Step_1}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 1</h3>
                <p className="text-[14px] leading-[20px] text-center w-[170px]">Click Start Promotion from the area below or in the navigation bar</p>
            </div>

            <div className="flex flex-col items-center border-r-[1px] px-[20px] w-full">
                <img
                    className="flex w-[32px] h-[32px] mb-5"
                    src={Step_2}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 2</h3>
                <p className="text-[14px] leading-[20px] text-center w-[150px]">Enter details of your promotion</p>
            </div>

            <div className={`${className} infoBoxFirst flex flex-col items-center pl-[20px] xl-1300:border-r-[1px] xl-1300:pr-[20px] w-full`}>
                <img
                    className="flex w-[32px] h-[32px] mb-5"
                    src={Step_3}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 3</h3>
                <p className="text-[14px] leading-[20px] text-center w-[150px]">Attach one or more programs to the promotion</p>
            </div>
            </div>

            <div className={`${className} wrapperSecond flex md:pt-[30px] xl-1300:basis-[40%] xl-1300:pt-0`}>
            <div className={`${className} infoBoxSecond flex flex-col items-center border-r-[1px] xl-1300:px-[20px] w-full`}>
                <img
                    className="flex w-[32px] h-[32px] mb-5"
                    src={Step_4}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 4</h3>
                <p className="text-[14px] leading-[20px] text-center w-[160px]">Submit the application</p>
            </div>

            <div className={`${className} infoBoxThird flex flex-col items-center xl-1300:pl-[20px] w-full`}>
                <img
                    className="flex w-[32px] h-[32px] mb-5"
                    src={Step_5}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 5</h3>
                <p className="text-[14px] leading-[20px] text-center w-[150px]">We will be in touch</p>
            </div>
            </div>
        </div>

    )
}
