import { useEffect } from "react";
import MugLoaderIcon from "../../assets/images/TBS_2.0_Loader.gif";
const MugLoader = () => {
  useEffect(() => {
    // Scroll to the middle of the page when the component is mounted
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render

  return (
    // <div className="text-center w-full py-[80px]">
    //   <div className="w-[100px] md:w-[200px] m-auto">
    //   <img src={MugLoaderIcon} width={10} height={14} alt="DownloadIcon" />
    //   </div>
    // </div>
    // <div className="fixed inset-0 flex items-center justify-center bg-white  z-50">
    <div className="fixed h-[100%] w-[100vw] flex items-center justify-center bg-white  z-[1000] overflow-y-hidden">
      <div className="flex justify-center w-[100px] mdtab:w-[200px]">
        <img src={MugLoaderIcon} width={150} height={150} alt="DownloadIcon" />
      </div>
    </div>
  );
};

export default MugLoader;
