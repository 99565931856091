interface ProgramHomeCardProps {
  badgeText: string;
  title: string;
  description: string;
}

export default function ProgramHighlightCard({ title, description, badgeText }: ProgramHomeCardProps) {
  return (
    <div className="flex flex-col relative  gap-[20px]">
      <div className="flex flex-col gap-[10px] border-b-[1px] p-b-[20px]">
        <div className="flex rounded-[30px] max-h-[32px] max-w-[207px] md-tab:max-w-[220px] lg-1200:max-w-[250px] xl-1440:max-w-[335px] text-ellipsis uppercase gap-[5px] text-[12px] font-GothamBold text-[#4A4F55] hover:bg-White px-[12px] py-[6px] border-[1px] border-[#4A4F55] w-fit">
          <span className=" truncate leading-[18px] font-GothamBold text-[12px] text-[#4A4F55] ">{badgeText}</span>
        </div>

        <h2 className=" text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[18px] leading-[28px] tracking-[0.16px] pb-[20px] truncate overflow-ellipsis font-GothamBold">
          {title}
        </h2>
      </div>

      <p className="text-[11px] sm:text-[10px] md:text-[11px] lg:text-[12px] xl:text-[14px] h-[50px] lg:h-[55px] xl:h-[60px] text-ellipsis line-clamp-3 font-Gotham">
        {description}
      </p>
    </div>
  );
}
