import { useEffect, useState } from "react";
import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import Contactbg from "../../assets/images/contactusbg.jpg";
import { useAlertStore } from "src/store/alert";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { exceptionHandler } from "src/utils/helper";

export default function TermsConditon() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlertStore();
  const [title, setTitle] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await api.termsCondition.data();
        if (res.status === 200) {
          if (Array.isArray(res.data?.data)) {
            const el = document.getElementById("termsConditionWrapper");
            const term = res.data.data[0];
            setTitle(term.title);
            if (el) {
              el!.innerHTML = term?.content;
            }
          }
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        exceptionHandler(error, setAlert, "destructive");
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <FullPageTemplate desktopBg={Contactbg} mobileBg={Contactbg} bannerTitle={title} loading={loading}>
      <div
        id="termsConditionWrapper"
        className="rule-regulation
                      [&>ol>li]:pl-[10px] 
                      [&>ol>li]:ml-[46px] 
                      [&>ol>li]:leading-[35px]
                      [&>ol>li>ul]:dot-list
                      [&>ol>li>ul>li>p>a]:inline-block
                      [&>p>a]:text-[#B95804] 
                      hover:[&_a]:underline 
                      [&_p]:mb-[30px]
                      [&_h2]:text-[18px] 
                      [&_h2]:md:text-[24px] 
                      [&_h2]:font-GothamBold
                      [&_h3]:mb-[24px] 
                      [&>ol>li]:mb-[40px] 
                      [&>ol>li:last-of-type]:mb-0
                      [&_p>strong]:font-normal
                      [&>h2]:mt-5 [&>ol>li]:mt-5 
                      [&>p]:mb-[10px] 
                      [&>ul]:dot-list
                      [&>ul]:mt-5 
                      [&>ul>li>p>strong]:font-normal
                      [&_a]:text-[#B95804] 
                      [&>ul>li>p>a]:inline 
                      hover:[&>ul>li>p>a]:underline  
                      [&>ul>li:last-of-type]:mb-[0px] 
                      [&:ul:li:p]:mb-0"
      />
    </FullPageTemplate>
  );
}
