import { Button } from "@headlessui/react";
import { ProgramStatus } from "src/types/promotion.type";

export default function ProgramStatusBadge({ status }: { status?: ProgramStatus }) {
  return (
    <div className={`flex items-center text-white w-[72px] rounded-[4px] ${
      status === ProgramStatus.APPROVED
       ? "bg-[#08C12F]"
       : status === ProgramStatus.IN_REVIEW
         ? "bg-[#F4B233]"
         : status === ProgramStatus.DENIED
           ? "bg-[#B02E2F]"
           : "bg-[#4A4F55]"
   }`}>
      <Button
      
      style={{
        borderRadius: 3,
        padding: "4px 10px 3px 10px",
        width: 74,
        color: "#fff",
        fontSize: 9,
        fontFamily: "Gotham-Bold",
        textTransform: "uppercase",
        border: 0,
        pointerEvents: "none",
      }}
      >
        {status}
        {/* <span style={{ marginTop: 2,}}>{status}</span> */}
      </Button>
    </div>

  );
}
