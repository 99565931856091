import { create } from "zustand";

interface GlobalLoader {
  loading: boolean;
  toggleLoading: () => void;
  setLoading: (val: boolean) => void;
}

export const useGlobalLoader = create<GlobalLoader>()((set) => ({
  loading: false,
  toggleLoading: () =>
    set((state) => ({
      loading: !state.loading,
    })),
  setLoading: (val) =>
    set((state) => ({
      loading: val,
    })),
}));
