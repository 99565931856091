import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  breadcrumbList?: any; // Make breadcrumbList optional
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breadcrumbList,
}: BreadcrumbProps) => {
  const isLastIndex = (index: number) =>
    breadcrumbList && index === breadcrumbList.length - 1;

  return (
    <>
      {breadcrumbList && (
        <div className="flex">
          <ul>
            {breadcrumbList.map((path: any, index: number) => (
              <li
                key={index}
                className="inline items-center breadcrumb-listitem"
              >
                {isLastIndex(index) && !path?.showAsLink ? (
                  <span className={`text-[12px] xs:text-[14px]  leading-[normal] ${isLastIndex(index) ? ' font-GothamBold' : 'font-sans'}`}>
                    {path?.key}
                  </span>
                ) : (
                  <>
                    <Link
                      to={path.value || '#'}
                      className={`text-[12px] xs:text-[14px]  leading-[normal] underline ${isLastIndex(index) ? ' font-GothamBold' : ''}`}
                    >
                      {path.key}
                    </Link>
                    {!isLastIndex(index) && <>&nbsp;/&nbsp;</>}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
