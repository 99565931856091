import { IPromotion } from "src/types/promotion.type";
import { create } from "zustand";

type State = {
  promotion: IPromotion;
  thankuId?: any;
};

type Actions = {
  setPromotion: (data: Partial<IPromotion> | IPromotion) => void;
  resetPromotion: () => void;
  setThankuId: (data?: any) => void;
};

export const promotionInitialState: IPromotion = {
  id: "",
  brandName: "",
  packSize: "",
  volume: "",
  container: "",
  articleNumber: "",
  skuPricing: "",
  offers: "",
  promoCase: "",
  advertisingSummary: "",
  program: [],
};

export const usePromotionStore = create<State & Actions>((set) => ({
  promotion: promotionInitialState,
  thankuId: undefined,
  setThankuId: (data) => set((state) => ({ ...state, thankuId: data })),
  setPromotion: (data) =>
    set((state) => ({ promotion: state?.promotion ? { ...state.promotion, ...data } : (data as IPromotion) })),
  resetPromotion: () => set((state) => ({ promotion: promotionInitialState })),
}));
