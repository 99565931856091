"use client";
import { useEffect, useState } from "react";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { Input } from "../../components/ui/input";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { resetPasswordSchema } from "../../lib/validationSchema/validation";
import Layout from "../layout/layout";
import HidePass from "../../assets/images/login/hide-pass.svg";
import ShowPass from "../../assets/images/login/show-pass.svg";
import api from "src/services/api";
import PageRoutes from "src/constants/pageRoutes";
import { ErrorMessages } from "src/constants/errorMessages";
import { useAlertStore } from "src/store/alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { exceptionHandler } from "src/utils/helper";
import Header from "../header";
import Fotter from "../footer";

const TOKEN_SEARCH_PARAM_KEY = "token";
export default function ResetPassword() {
  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const [isResettingPassword, setIsResettingPasssword] = useState<boolean>();
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const { setAlert } = useAlertStore();

  const toggleShowPassword = (type: "password" | "confirmPassword") => {
    setShowPassword((prev) => ({ ...prev, [type]: !prev[type] }));
  };
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const onSubmit = form.handleSubmit(async (data) => {
    const token = searchParams.get(TOKEN_SEARCH_PARAM_KEY);
    setIsResettingPasssword(true);
    try {
      const res = await api.auth.resetPassword({ resetToken: token, newPassword: data.password });
      if (res.status === 200) {
        navigate(PageRoutes.LOGIN);
        setAlert({ message: res.data.message, variant: "successful" });
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setIsResettingPasssword(false);
    }
  });

  useEffect(() => {
    const token = searchParams.get(TOKEN_SEARCH_PARAM_KEY);
    if (!token || token?.length < 5) {
      navigate(PageRoutes.LOGIN);
    } else {
      (async () => {
        try {
          const res = await api.miscellaneous.verifyToken({ token });
          if (res.status !== 200) {
            throw new Error();
          }
        } catch (error) {
          console.error(error);
          setAlert({
            message: ErrorMessages.RESET_TOKEN_EXPIRE_ERROR,
            isOpen: true,
            variant: "destructive",
          });
          navigate(PageRoutes.FORGOT_PASSWORD);
        }
      })();
    }
  }, []);

  return (
    <>
      <div className="flex flex-col h-[100vh]">
        <Header />
        <div className="flex justify-center items-center h-full custom-container-four">
          <div className={`flex flex-col max-w-[455px] w-1/2 h-full justify-center py-[20px]`}>
            <h1
              className={`mb-[20px] leading-[50px] text-center text-[32px] xs:mb-5  font-GothamBold text-[#000000] md:text-[40px]`}
            >
              Reset Password
            </h1>

            <p className="mb-[10px] text-center text-[#4A4F55]">Enter a new password</p>
            <p className="mb-[20px] text-center text-[#4A4F55]">
              Please make sure your password has the following requirements: a minimum of 8 characters, at least 1
              letter, 1 number, and 1 special character (!,@,#,$,%,^,&,*).
            </p>
            <Form {...form}>
              <form
                onSubmit={onSubmit}
                className="max-w-[700px] [&_.reply-input>input]:text-[#4A4F55] [&_.reply-input>input]:placeholder:text-[14px]  flex flex-col [&_.error-msg]:text-[12px] [&_.error-msg]:text-[#bf1332] [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.form-label]:text-[14px] [&_.form-label]:font-normal [&_.form-label]:font-sans [&_.form-label]:leading-5 [&_.form-itm]:mb-5 [&_.form-checkbox]:my-[10px]"
              >
                <div className="flex flex-col">
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem className="form-itm">
                        <FormLabel className="form-label">
                          New Password
                          <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                        </FormLabel>
                        <Input
                          maxLength={25}
                          type={showPassword.password ? "text" : "password"}
                          className={`reply-input ${
                            form.formState.errors.password ? "border-[#bf1332]" : "border-[#d6d6d6]"
                          }`}
                          placeholder="New Password"
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === form.watch("confirmPassword")) {
                              form.clearErrors("confirmPassword");
                            }
                            field.onChange(value);
                          }}
                          endIcon={
                            <img
                              tabIndex={0}
                              onClick={() => toggleShowPassword("password")}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  toggleShowPassword("password");
                                }
                              }}
                              src={showPassword.password ? ShowPass : HidePass}
                              height={16}
                              width={16}
                              alt="eye"
                              className="cursor-pointer eyeicon"
                            />
                          }
                        />
                        <FormMessage className="error-msg align-start items-start [&_>p]:leading-[12px] [&_>p]:ml-[3px]" />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem className="form-itm">
                        <FormLabel className="form-label">
                          Confirm New Password
                          <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                        </FormLabel>
                        <Input
                          maxLength={25}
                          type={showPassword.confirmPassword ? "text" : "password"}
                          className={`reply-input ${
                            form.formState.errors.confirmPassword ? "border-[#bf1332]" : "border-[#d6d6d6]"
                          }`}
                          placeholder="Confirm New Password"
                          {...field}
                          endIcon={
                            <img
                              tabIndex={0}
                              onClick={() => toggleShowPassword("confirmPassword")}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  toggleShowPassword("confirmPassword");
                                }
                              }}
                              src={showPassword.confirmPassword ? ShowPass : HidePass}
                              height={16}
                              width={16}
                              alt="eye"
                              className="cursor-pointer eyeicon"
                            />
                          }
                        />
                        <FormMessage className="error-msg align-start" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  type="submit"
                  className="uppercase h-[auto] max-h-[50px] text-[16px] font-GothamBold leading-5 mt-5 rounded-[200px] px-[30px] py-[15px] "
                  disabled={isResettingPassword}
                >
                  {isResettingPassword ? "Please Wait..." : "Reset"}
                </Button>
              </form>
            </Form>
          </div>
        </div>
        <Fotter />
      </div>
    </>
  );
}
