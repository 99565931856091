import { Form, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
// import { PROMO_CASE} from "../constants";
import { CONTAINER_OPTIONS, PACKED_SIZE_OPTIONS } from "../constants";
import DropdownSelect from "../components/dropDown";
import { promotionSchema } from "../lib/validationSchema/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import { useAlertStore } from "src/store/alert";
import { exceptionHandler } from "src/utils/helper";
import PageRoutes from "src/constants/pageRoutes";
import { usePromotionStore } from "src/store/promotion";
import { IPromotion } from "src/types/promotion.type";
import { useGlobalLoader } from "src/store/loader";
import { SuccessMessages } from "src/constants/successMessages";
import SearchBar from "src/modules/searchBar";

export interface EditPromotionalFormProps {
  editForm?: boolean;
  closeEdit?: () => void;
  promotion: IPromotion;
}

const PromotionalForm = ({ editForm, closeEdit, promotion }: EditPromotionalFormProps) => {
  // const [brands, setBrands] = useState([]);
  // const validationSchema = promotionSchema(brands);
  // const [loading, setLoading] = useState(true);
  const form = useForm<z.infer<typeof promotionSchema>>({
    resolver: zodResolver(promotionSchema),
    defaultValues: promotion,
    mode: "onChange",
  });
  const navigate = useNavigate();

  const [isAddingPromotion, setIsAddingPromotion] = useState(false);
  const { setAlert } = useAlertStore();
  const { setPromotion } = usePromotionStore();
  const { toggleLoading } = useGlobalLoader();
  const charCount = form.watch("advertisingSummary")?.length || 0



  // // Fetch Brands
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const res = await api.miscellaneous.getBrandList();
  //       if (res.status == 200) {
  //         if (res?.data?.data && Array.isArray(res.data.data)) {
  //           // setBrands(res.data.data.map((item: any) => item.name));
  //           return;
  //         }
  //       }
  //       throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
  //     } catch (error) {
  //       exceptionHandler(error, setAlert, "destructive");
  //       navigate(PageRoutes.LOGIN);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      window.scrollTo(400, 400);
    }
  }, [form.formState.errors]);

  const formKeyDown = (e: any) => {
    if (
      e.key == "Enter" &&
      e.target.name == "brandName" &&
      document.getElementsByClassName("dropDownContainer").length
    ) {
      e.preventDefault();
    }
  };

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      setIsAddingPromotion(true);
      toggleLoading();
      const res = await (editForm ? api.promotion.update(promotion.id, { ...data }) : api.promotion.add({ ...data }));
      if (res.status === 201 || res.status === 200) {
        setPromotion(res.data);
        if (editForm) {
          closeEdit!();
          setAlert({ message: SuccessMessages.PROMOTION_UPDATE_SUCCESS, variant: "successful", title: "" });
        } else {
          setAlert({ message: SuccessMessages.PROMOTION_ADD_SUCCESS, variant: "successful", title: "" });
          setTimeout(() => {
            navigate(PageRoutes.PROGRAM);
          }, 0);
        }
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setIsAddingPromotion(false);
      toggleLoading();
    }
  });
  return (
    <div>
      <h2 className="mb-[30px] text-[24px] leading-[34px] font-GothamBold">
        {!editForm ? "" : "Edit"} Promotional Details
      </h2>
      <Form {...form}>
        <form
          className="flex flex-col [&_.error-msg]:text-[12px] [&_.error-msg]:text-[#bf1332] 
        [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.form-label]:text-[14px] 
        [&_.form-label]:font-normal  [&_.form-label]:text-[#4A4F55] [&_.form-label]:font-sans [&_.form-label]:leading-5 [&_.form-itm]:mb-[24px] [&_.form-checkbox]:my-[10px] [&_.reply-input>input]:placeholder:text-[#4A4F55] [&_.form-itm>button>span]:text-[#4A4F55] [&_.form-itm>textarea]:placeholder:text-[#4A4F55] [&_.reply-input>input]:text-[#4A4F55] [&_.form-itm>textarea]:text-[#4A4F55] [&_input]:py-[15px]"
          onSubmit={onSubmit}
          onKeyDown={formKeyDown}
        >
          <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="brandName"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="brandName">
                      Brand Name
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    {/* <SearchBar
                      {...field}
                      maxLength={25}
                      className={`reply-input ${
                        form.formState.errors.brandName ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      aria-label="please enter your name"
                      placeholder={"Enter brand name"}
                      {...field}
                      data={brands}
                      showSearchIcon={false}
                      onChange={field.onChange}
                      loading={loading}
                    /> */}
                    <Input
                      id="brandName"
                      maxLength={31}
                      aria-label="please enter your name"
                      placeholder={"Enter brand name"}
                      className={`reply-input ${
                        form.formState.errors.brandName ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                    />

                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="packSize"
                render={({ field }) => (
                  <FormItem
                    className={`form-itm  ${
                      form.formState.errors.packSize ? "[&>button]:border-[#bf1332]" : "[&>button]:border-[#d6d6d6]"
                    } `}
                  >
                    <FormLabel className="form-label" htmlFor="packSize">
                      Pack Size
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    <Input
                      id="packSize"
                      maxLength={3}
                      placeholder={"Eg: 12"}
                      className={`reply-input ${
                        form.formState.errors.packSize ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                      onChange={(event) => {
                        const val = event.target.value;
                        if (val.match(/^[0-9]*$/)) {
                          field.onChange(val);
                        }
                      }}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
            {/* <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="volume"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="volume">
                      Volume
                    </FormLabel>
                    <Input
                      id="Volume"
                      maxLength={25}
                      placeholder={"ENTER VOLUME' TO 'ENTER UNIT VOLUME IE. 341ML, 355ML"}
                      className={`reply-input ${
                        form.formState.errors.volume ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div> */}
            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="container"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="container  ">
                      Container
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    <DropdownSelect
                      {...field}
                      id="container"
                      maxLength={10}
                      scroll
                      onChange={field.onChange}
                      options={CONTAINER_OPTIONS}
                      defaultValue={field.value || "Select Container"}
                      className="min-w-[156px] relative [&>button]:pb-[15px]"
                      error={form?.formState?.errors?.container ? true : false}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="articleNumber"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="articleNumber">
                      Article Number
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    <Input
                      id="articleNumber"
                      maxLength={7}
                      placeholder={"Enter the 7-digit TBS assigned article number"}
                      className={`reply-input ${
                        form.formState.errors.articleNumber ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                      onChange={(event) => {
                        const val = event.target.value;
                        if (val.match(/^[0-9]*$/)) {
                          field.onChange(val);
                        }
                      }}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="skuPricing"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="container  ">
                      SKU Pricing
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    <Input
                      id="skuPricing"
                      maxLength={5}
                      placeholder={"Eg: $3.49 + HST & Deposit"}
                      className={`reply-input ${
                        form.formState.errors.skuPricing ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                      onChange={(event) => {
                        const val = event.target.value;
                        if (val.match(/^[0-9]*\.?[0-9]*$/)) {
                          field.onChange(val);
                        }
                      }}
                    />
                    <FormMessage className="error-msg " />
                  </FormItem>
                )}
              />
            </div>

            <div className="basis-full md:basis-1/2">
              <FormField
                control={form.control}
                name="offers"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel className="form-label" htmlFor="offers">
                      Offers
                      <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
                    </FormLabel>
                    <Input
                      id="offers"
                      maxLength={25}
                      placeholder={"Eg: $3 LTO/Consumer Promotion"}
                      className={`reply-input ${
                        form.formState.errors.offers ? "border-[#bf1332]" : "border-[#d6d6d6]"
                      }`}
                      {...field}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          {/* <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
            
            <div className="basis-full md:basis-1/2">
            <FormField
                control={form.control}
                name="promoCase"
                render={({ field }) => (
                  <FormItem
                    className={`form-itm  ${
                      form.formState.errors.promoCase ? "[&>button]:border-[#bf1332]" : "[&>button]:border-[#d6d6d6]"
                    } `}
                  >
                    <FormLabel className="form-label" htmlFor="promoCase">
                      Promo Case
                    </FormLabel>
                    <DropdownSelect
                      {...field}
                      id="skuPricing"
                      maxLength={25}
                      scroll
                      onChange={field.onChange}
                      options={PROMO_CASE}
                      defaultValue={field.value || "Select Promo case"}
                      className="min-w-[156px] relative [&>button]:pb-[15px]"
                      // className="min-w-[156px] relative [&>button]:pb-[15px]"
                      error={form?.formState?.errors?.promoCase ? true : false}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
            </div>
          </div> */}
          {/* <div className="basis-full max-w-[882px] [&>div]:!mb-0"> */}
          <div className="flex md:flex-row flex-col xs:gap-[0px] md:gap-[30px]">
      <div className="basis-full md:basis-1/2 [&>div]:!mb-0">
        <FormField
          control={form.control}
          name="advertisingSummary"
          render={({ field }) => (
            <FormItem className="form-itm">
              <FormLabel className="form-label" htmlFor="comments">
                Advertising Summary
                <sup className="text-[#AD2F33] text-[15px] align-text-bottom">*</sup>
              </FormLabel>
              <Textarea
                id="comments"
                maxLength={300}
                placeholder={"Enter Advertising Summary"}
                className={`py-[15px] px-[20px] rounded-[10px] resize-none h-full min-h-[160px] ${
                  form.formState.errors.advertisingSummary ? "border-[#bf1332]" : "border-[#d6d6d6]"
                }`}
                {...field}
                aria-label="please enter your comment"
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
              <FormMessage className="error-msg" />
              <div className={`text-right text-xs mt-1 ${ charCount === 300 ? "text-[#AD2F33]" : "text-[#4A4F55]"} `}>
                {charCount} / 300
              </div>
            </FormItem>
          )}
        />
      </div>
      <div className="basis-1/2"></div>
    </div>

          {!editForm ? (
            <div
              className={`contact-btn flex mt-10 
          justify-between
        `}
            >
              <div>
                <Button
                  variant={"outlineyellow"}
                  type="button"
                  onClick={() => navigate(PageRoutes.HOME)}
                  className="uppercase h-[50px] md:w-full text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]  "
                  disabled={isAddingPromotion}
                >
                  Back Home
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  className="uppercase h-[50px] md:w-full text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]"
                  disabled={isAddingPromotion}
                >
                  {isAddingPromotion ? "Please wait.." : "Choose Programs"}
                </Button>
              </div>
            </div>
          ) : (
            <div
              className={`contact-btn flex pt-[30px] pb-[40px] gap-[30px]
              justify-start"
            `}
            >
              <div>
                <Button
                  variant={"outlineyellow"}
                  type="button"
                  onClick={closeEdit}
                  className="uppercase h-[50px] md:w-full text-[16px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]  "
                  disabled={isAddingPromotion}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  className="uppercase h-[50px] md:w-full text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]  "
                  disabled={isAddingPromotion}
                >
                  {isAddingPromotion ? "Please wait.." : "Save Changes"}
                </Button>
              </div>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};
export default memo(PromotionalForm);
