import * as React from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { Badge } from "../components/ui/badge";

import { Link } from "react-router-dom";
import EqualHeight from "../lib/equalHeight";
import FeatureBadge from "../assets/images/featureBadge.svg";
interface InfoCardProps {
  title: string;
  description?: string | JSX.Element;
  content?: string | JSX.Element;
  image?: {
    url: string;
    alt?: string;
    width?: number | `${number}` | any;
    height?: number | `${number}` | any;
  };
  buttonText?: string;
  badgeText?: string;
  buttonIcon?: JSX.Element;
  onClickButton?: () => void;
  className?: string | undefined;
  asLink?: boolean;
  buttonLink?: string | undefined;
  from?: string;
  buttonTarget?: string;
  buttonAriaLabel?: string;
  index?: number;
}

const InfoCard = ({
  title,
  description,
  image,
  content,
  buttonText,
  onClickButton,
  badgeText,
  buttonIcon,
  className,
  asLink,
  buttonLink,
  from,
  index,
  buttonAriaLabel,
  buttonTarget,
}: InfoCardProps) => {
  EqualHeight("main-info", "info-height");
  //   const datalayer = useDataLayer();

  const bagColor = [
    "Digital",
    "Planning Tools",
    "Sale of data",
    "External Advertising",
    "Bolton Exclusive",
    "Retail",
  ].includes(badgeText || "")
    ? "#4A4F55"
    : "#406325";

  return (
    <Card
      //   onClick={onCardSelect}
      className={cn(" flex flex-col border border-solid rounded-[10px] relative overflow-hidden", className)}
    >
      <CardHeader className="info-header rounded-t-[5px] imgTabBorderWrapper bg-[#fff]">
        {image ? (
          asLink ? (
            <Link to={buttonLink || ""} className="pt-0">
              <div className="top-0 left-0 object-contain">
                <img
                  src={image?.url}
                  alt={image?.alt}
                  width={image?.width}
                  height={image?.height}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  className="info-image"
                />
              </div>
            </Link>
          ) : (
            <div className="top-0 left-0 object-contain">
              <img
                src={image?.url}
                alt={image?.alt}
                width={image?.width}
                height={image?.height}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                className="info-image"
              />
            </div>
          )
        ) : null}
      </CardHeader>

      <CardContent className="py-4 info-content">
        {title && (
          <div>
            <CardTitle className=" text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[18px] leading-[25px] font-sans font-bold info-title tracking-[0.16px] pb-[10px] truncate overflow-hidden">
              {title}
            </CardTitle>
          </div>
        )}
        {description && (
          <div>
            <CardDescription className=" text-[11px] sm:text-[10px] md:text-[11px] lg:text-[12px] xl:text-[13px] info-description font-sans font-normal overflow-hidden">
              {description}
            </CardDescription>
          </div>
        )}
        {badgeText && (
          <Badge
            variant={["Featured"].includes(badgeText) ? "secondary" : "default"}
            className={`info-badge rounded-[30px] uppercase gap-[5px] text-[#fff] hover:bg-White px-2 py-1 leading-4 justify-center absolute right-4 top-4 topBadge`}
          >
            {["Featured"].includes(badgeText) && <img src={FeatureBadge} alt="featureBadge" height={12} width={12} />}
            <span className="flex items-center">{badgeText}</span>
          </Badge>
        )}

        {content}
        {buttonText ? (
          <CardFooter className="flex p-0 info-footer">
            <Button
              asChild={asLink}
              className={`${
                asLink ? "tbs-link-text p-0 h-auto " : "w-full rounded-[100px]"
              } text-[11px] font-sans font-bold`}
              onClick={onClickButton}
              variant={asLink ? "link" : "default"}
            >
              <>
                {asLink ? (
                  <Link
                    to={`${buttonLink}`}
                    className={"p-0 text-[#D06F1A] hover:underline"}
                    target={buttonTarget ? "_blank" : "_self"}
                    aria-label={buttonAriaLabel}
                  >
                    {buttonText}
                  </Link>
                ) : (
                  buttonText
                )}
                {buttonIcon}
              </>
            </Button>
          </CardFooter>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default InfoCard;
