import React, { useRef } from "react";
import { cn } from "../../lib/utils";
interface bannerHeader {
  title?: string;
  children?: React.ReactNode;
  className?: string | undefined;
  subtitle?: string;
  desktopBg?: string | undefined;
  ipadBg?: string | any;
  mobileBg?: string | undefined;
  BannerAnalyticsEvent?: string;
}
export default function BannerHeader({
  title,
  desktopBg,
  ipadBg,
  mobileBg,
  className,
  subtitle,
  BannerAnalyticsEvent,
  children,
}: bannerHeader) {
  const cardRef = useRef(null);

  return (
    <section
      className={
        title || desktopBg ? cn("flex flex-row min-h-[200px] relative max-w-[1920px] mx-auto", className) : "hidden"
      }
    >
      <img alt={`${desktopBg}+desktop`} src={desktopBg || ""} className={"object-cover hidden lg:block"} />
      <img alt={`${mobileBg}+mobile`} src={mobileBg || ""} className={"object-cover block lg:hidden"} />

      <div
        ref={cardRef}
        id={"banner"}
        className={` ${
          title && "bg-black bg-opacity-70"
        } flex flex-col absolute left-0 h-full right-0 justify-center items-center p-[15px] print-title`}
      >
        <h1
          className={
            title
              ? "text-[32px] text-center font-sans lg:text-[48px] font-bold leading-[34px] lg:leading-[60px] text-white"
              : `hidden`
          }
        >
          {title}
        </h1>
        <p className={subtitle ? `text-[#fff] font-sans mt-[10px] text-[16px] text-center` : `hidden`}>{subtitle}</p>
        {children}
      </div>
    </section>
  );
}
