import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import * as z from "zod";
import { useState } from "react";
import { Link } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";
import api from "src/services/api";
import Fotter from "../footer";
import Header from "../header";
import { SuccessMessages } from "src/constants/successMessages";
import { ErrorMessages } from "src/constants/errorMessages";

const formSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "This field is required.",
    })
    .email("Please enter a valid email address."),
});

export default function ForgotPassword({ res = "" }) {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      setIsSendingRequest(true);
      const res = await api.auth.forgotPassword({ ...data });
      if (res.status == 200 || res.status == 201) {
        form.reset();
        setShowMessage(SuccessMessages.FORGOT_PASS_SUCCESS);
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      setShowMessage(SuccessMessages.FORGOT_PASS_ERROR);
      console.log(error);
    } finally {
      setIsSendingRequest(false);
    }
  });

  return (
    <>
      <div className="flex flex-col h-[100vh]">
        <Header />
        <div className="flex justify-center items-center h-full custom-container-three">
          <div className={`flex flex-col max-w-[455px] w-1/2 h-full justify-center`}>
            <h1
              className={`mb-[24px] leading-[50px] text-center text-[32px] xs:mb-5  font-GothamBold text-[#000000] md:text-[40px] `}
            >
              Forgot Password
            </h1>
            {showMessage && (
              <div className="mb-[20px] bg-[#F4B233] border-[#F4B233] text-black p-[15px] rounded-[10px]">
                <p>{showMessage}</p>
              </div>
            )}
            <p className="mb-[15px] xs:mb-[40px] text-[16px] leading-[24px] text-[#4A4F55] text-center font-sans">
              Please enter your email address below
              <br />
              to reset your password:
            </p>
            <Form {...form}>
              <form
                onSubmit={onSubmit}
                className="[&_.form-label]:text-[14px] [&_.reply-input>input]:text-[#4A4F55] [&_.reply-input>input]:py-[15px] [&_.reply-input>input]:placeholder:text-[14px] [&_.form-label]:font-normal [&_.form-label]:font-sans [&_.form-label]:leading-5 [&_.form-itm]:mb-[40px]"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="form-itm">
                      <FormLabel htmlFor="email" className="form-label text-[#4A4F55] font-sans">
                        Email Address
                        <sup className="text-[#AD2F33] text-[14px] align-text-bottom">*</sup>
                      </FormLabel>
                      <Input
                        id="email"
                        maxLength={50}
                        className={`reply-input ${
                          form.formState.errors.email ? "border-[#bf1332]" : "border-[#d6d6d6]"
                        } text-[#4A4F55]`}
                        placeholder="Enter Email Address"
                        {...field}
                        onChange={(event) => {
                          const cleanedValue = event.target.value.replace("+", "").trim();
                          field.onChange(cleanedValue);
                        }}
                      />
                      <FormMessage className="text-[#bf1332] text-[12px] align-text-bottom" />
                    </FormItem>
                  )}
                />

                <div>
                  <Button
                    type="submit"
                    className={`w-full h-[50px] uppercase font-bold text-[16px] leading-[20px] rounded-[25px] mb-[30px] px-[30px] py-[15px] font-GothamMedium`}
                    disabled={isSendingRequest}
                  >
                    {isSendingRequest ? "Please Wait..." : "SUBMIT"}
                  </Button>
                  <div className="text-center ">
                    <Link
                      to={PageRoutes.LOGIN}
                      className="text-[#D06F1A] text-[14px] leading-[20px] font-GothamMedium hover:underline"
                    >
                      Back to Sign in
                    </Link>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
        <Fotter />
      </div>
    </>
  );
}
