import { FullPageTemplate } from "src/modules/programsLayout/fullPageTemplate";
import PromotionalForm from "../../components/promotionalForm";
import { promotionInitialState } from "src/store/promotion";
import PageRoutes from "src/constants/pageRoutes";
import Steps from "src/components/steps";

const breadcrumb = [
  {
    id: "1",
    key: "Home",
    value: PageRoutes.HOME,
  },
  {
    id: "2",
    key: "Promotional Details",
    value: PageRoutes.PROMOTION,
  },
];

export default function PersonalDetails() {
  return (
    <FullPageTemplate breadcrumbList={breadcrumb}>
      <div className="flex flex-col gap-[40px]">
      <Steps />
      <PromotionalForm promotion={promotionInitialState} />
      </div>
    </FullPageTemplate>
  );
}
