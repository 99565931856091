import { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogContent, DialogClose } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import Close from "../../assets/images/close.svg";
import { DialogTitle } from "@radix-ui/react-dialog";
import PageRoutes from "src/constants/pageRoutes";
import api from "src/services/api";
import { useAlertStore } from "src/store/alert";
import { usePromotionStore } from "src/store/promotion";
import { ErrorMessages } from "src/constants/errorMessages";
import { exceptionHandler } from "src/utils/helper";
import { useGlobalLoader } from "src/store/loader";

interface NewSubmissionConfirmationModal {
  navigate: (...args: any) => void;
}

export interface NewSubmissionConfirmationModalRef {
  toggleOpen: (url?: string) => void;
  promotionRoute: () => void;
}

const NewSubmissionConfirmationModal = forwardRef(({ navigate }: NewSubmissionConfirmationModal, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    toggleOpen,
    promotionRoute() {
      if (promotion.id) {
        toggleOpen();
        return;
      }
      navigate(PageRoutes.PROMOTION);
    },
  }));
  const { setAlert } = useAlertStore();
  const { promotion, resetPromotion } = usePromotionStore();
  const { toggleLoading } = useGlobalLoader();

  function toggleOpen(data: any = "") {
    setOpen(!open);
  }

  const continuePrevSubmissionHandler = () => {
    navigate(PageRoutes.promotionDetail(promotion.id));
    toggleOpen();
  };

  async function newSubmissionHandler() {
    try {
      toggleLoading();
      const res = await api.promotion.deleteById(promotion.id);
      if (res.status == 200 || res.status == 201) {
        resetPromotion();
        setTimeout(() => {
          navigate(PageRoutes.PROMOTION);
        }, 0);
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      toggleOpen();
      toggleLoading();
    }
  }
  return (
    <Dialog open={open}>
      <DialogContent className="bg-[#fff] items-center  rounded-[10px] pt-[60px] px-[15px] py-[30px] 3xs:p-[30px] md:p-[40px] w-[90%] md:max-w-[500px]">
        <div className="flex flex-col gap-[20px] items-center [&>h3]:text-center [&>h3]:text-[24px] [&>h3]:text-[#000] [&>h3]:mb-[20px] [&>p]:text-[16px] [&>p]:mb-[30px] [&>p]:max-w-[373px] [&>p]:text-center">
          <DialogTitle className="flex flex-col md:flex-row gap-[20px] w-full justify-center items-center text-center">
            <p className="text-[32px] leading-[42px] font-GothamBold ">You Have an Uncompleted Submission</p>
          </DialogTitle>

          <div className="flex flex-col md:flex-row gap-[20px] w-full justify-center items-center text-center">
            <p className="text-[14px] leading-[24px] whitespace-pre-line">
              It looks like you have a submission that isn't finished yet. <br /> If you choose to continue, your previous steps will <br />not be saved.
            </p>
          </div>

          <div className="flex flex-col w-full justify-center gap-[20px] pt-[20px]">
            <div>
              <Button
                type="button"
                variant={"link"}
                className="uppercase h-[50px] md:w-[100%] text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]  bg-[#F4B233] hover:bg-[#fff]  text-[#000]  border-[#f4b233] border-2 border-solid"
                onClick={newSubmissionHandler}
              >
                Continue anyway
              </Button>
            </div>

            <div>
              <Button
                variant={"link"}
                type="button"
                className="uppercase h-[50px] md:w-[100%] text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px]  bg-[#fff] hover:bg-[#000] text-[#000] hover:text-[#fff] border-[#F4B233] hover:border-[#000] border-2 border-solid"
                onClick={continuePrevSubmissionHandler}
              >
                Go back to previous submission
              </Button>
            </div>
          </div>
        </div>

        <DialogClose>
          <div
            className="w-[20px] h-[20px] absolute top-5 right-5 flex items-center justify-center appearance-none"
            onClick={toggleOpen}
          >
            <img src={Close} alt="close" className="w-full h-full" />
          </div>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
});

export default NewSubmissionConfirmationModal;
