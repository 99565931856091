import { IZone, ProgramType } from "./program.type";

export interface IPromotion {
  brandName: string;
  packSize: string;
  volume: string;
  container: string;
  articleNumber: string;
  skuPricing: string;
  offers: string;
  promoCase: string;
  advertisingSummary: string;
  id: string;
  status?: "DRAFT" | "REVIEWED" | "APPROVED" | "PENDING";
  program?: PromotionProgram[];
  submissionDate?: string;
}

export enum ProgramStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  IN_REVIEW = "IN REVIEW",
}

interface PromotionProgram {
  id: number;
  name: string;
  image: string;
  programId: number;
  category: string;
  programType: ProgramType;
  status?: ProgramStatus;
  cycle: {
    id: number;
    programCycleId: number;
    name: string;
    value?: string;
    year: string;
  };
  promotionZoneStore: {
    id: number;
    programZoneId: number;
    name: string;
    value: string;
    storeExcel?: string;
  }[];
}
