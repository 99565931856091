import { Routes, Route, Outlet } from "react-router-dom";
import Login from "../pages/login";
import Register from "../pages/register";
import Home from "../pages/home";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import ThankYouMessage from "../pages/thankyou";
import Programs from "../pages/programs";
import ContactUs from "../pages/contactUs";
import TermsCondition from "../pages/termsCondition";
import ProgramDetail from "../pages/programDetail";
import Promotion from "../pages/promotion";
import PromotionDetail from "../pages/promotionalDetails";
import FAQ from "../pages/faq";
import NotFound from "src/pages/notFound";
import IsProtectedRoute from "./isProtectedRoute";
import PageRoutes from "src/constants/pageRoutes";
import IsPromotionalUser from "./isPromotionalUser";
import Submission from "src/pages/submission";

function RoutesWrapper() {
  return (
    <Routes>
      <Route element={<IsProtectedRoute is={true} />}>
        <Route path={PageRoutes.HOME} element={<Home />} />
        <Route path={PageRoutes.SUBMISSIONS} element={<Submission />} />
        <Route element={<IsPromotionalUser />}>
          <Route path={PageRoutes.PROGRAM} element={<Outlet />}>
            <Route index element={<Programs />} />
            <Route path=":id" element={<ProgramDetail />} />
          </Route>
        </Route>
        <Route path={PageRoutes.PROMOTION} element={<Outlet />}>
          <Route element={<IsPromotionalUser isNew={true} />}>
            <Route index element={<Promotion />} />
          </Route>
          <Route path=":id" element={<PromotionDetail />} />
        </Route>
        <Route path={PageRoutes.THANK_YOU} element={<ThankYouMessage />} />
      </Route>
      <Route element={<IsProtectedRoute is={false} />}>
        <Route path={PageRoutes.LOGIN} element={<Login />} />
        <Route path={PageRoutes.REGISTER} element={<Register />} />
        <Route path={PageRoutes.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={PageRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
      </Route>
      <Route path={PageRoutes.CONTACT_US} element={<ContactUs />} />
      <Route path={PageRoutes.TERMS_CONDITION} element={<TermsCondition />} />
      <Route path={PageRoutes.FAQ} element={<FAQ />} />
      <Route path={PageRoutes.NOT_FOUND} element={<NotFound />} />
    </Routes>
  );
}

export default RoutesWrapper;
