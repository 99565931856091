import { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogContent, DialogClose, DialogTitle } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import Close from "../../assets/images/close.svg";
import Caution from "../../assets/images/Caution.svg";

interface SaveChangesConfirmationModal {
  onSubmit: (data: any) => void;
  onCancel: (data: any) => void;
  title: string;
}
const SaveChangesConfirmationModal = forwardRef(({ onSubmit, onCancel, title }: SaveChangesConfirmationModal, ref) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>("");
  useImperativeHandle(ref, () => ({
    toggleOpen(data: any = "") {
      setOpen(!open);
      setData(data);
    },
  }));
  return (
    <Dialog open={open}>
      <DialogContent className="bg-[#fff] items-center  rounded-[10px] px-[15px] py-[30px] 3xs:p-[30px] md:p-[40px] w-[90%] md:max-w-[500px]">
        <div className="flex flex-col gap-[30px] items-center [&>h3]:text-center [&>h3]:text-[24px] [&>h3]:text-[#000] [&>h3]:mb-[20px] [&>p]:text-[16px] [&>p]:mb-[30px] [&>p]:max-w-[373px] [&>p]:text-center">
          <img src={Caution} alt="deleteImage" className="w-[80px] h-[80px]" />

          <div className="flex flex-col md:flex-row gap-[20px] w-full justify-center items-center text-center">
            <DialogTitle className="text-[16px] leading-[24px] whitespace-pre-line">{title}</DialogTitle>
          </div>

          <div className="flex flex-row w-full justify-center gap-[30px]">
            <div>
              <Button
                type="button"
                variant={"link"}
                // className="uppercase h-[50px] md:w-[100px] text-[14px] font-sans font-bold leading-5 rounded-[200px] px-[30px] py-[15px]  "
                className="uppercase h-[50px] md:w-[100px] text-[14px] font-sans font-bold leading-5 rounded-[200px] px-[30px] py-[15px]  bg-[#F4B233] hover:bg-[#fff]  text-[#000]  border-[#f4b233] border-2 border-solid"
                onClick={() => onCancel(data)}
              >
                No
              </Button>
            </div>

            <div>
              <Button
                variant={"link"}
                type="button"
                // className="uppercase h-[50px] md:w-[100px] text-[14px] font-sans font-bold leading-5 rounded-[200px] px-[30px] py-[15px]  "
                className="uppercase h-[50px] md:w-[100px] text-[14px] font-sans font-bold leading-5 rounded-[200px] px-[30px] py-[15px]  bg-[#000] hover:bg-[#fff]  text-[#fff] hover:text-[#000] border-[#000] border-2 border-solid"
                onClick={() => onSubmit(data)}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>

        <DialogClose>
          <div
            className="w-[20px] h-[20px] absolute top-5 right-5 flex items-center justify-center appearance-none"
            onClick={() => onCancel(data)}
          >
            <img src={Close} alt="close" className="w-full h-full" />
          </div>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
});

export default SaveChangesConfirmationModal;
