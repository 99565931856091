import { useState } from "react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Button } from "../../components/ui/button";
import DownloadIcon from "../../assets/images/downloadIcon.svg";
import { Checkbox } from "../../components/ui/checkbox";
import Close from "../../assets/images/close.svg";
import { IZone } from "src/types/program.type";
import { UseFormReturn } from "react-hook-form";
import z from "zod";
import { programSchema } from "src/lib/validationSchema/validation";
import { downloadFileByUrl } from "src/utils/helper";
import { useAlertStore } from "src/store/alert";

interface IZoneProps {
  form: UseFormReturn<z.infer<typeof programSchema>, any, undefined>;
  zone: IZone[];
  guideUrl: string;
  limit?: string;
}

export default function Zone({ form, zone, guideUrl, limit }: IZoneProps) {
  const [loadMore, setLoadMore] = useState(8);
  const { setAlert } = useAlertStore();

  return (
    <div className="flex flex-col gap-[40px]">
      <div className="flex flex-col gap-[30px]">
        <div className="flex gap-5 justify-start items-center">
          <h2 className="flex items-center mb-0 text-[24px] leading-[34px] font-GothamBold">Select a Zone</h2>
          <Button
            variant={"outlineyellow"}
            type="button"
            onClick={() => downloadFileByUrl(guideUrl)}
            className="rounded-[30px] font-GothamMedium gap-[10px] bg-white hover:bg-white uppercase"
          >
            Download Guide
            <span>
              <img src={DownloadIcon} width={10} height={14} alt="DownloadIcon" />
            </span>
          </Button>
        </div>
        <div>
          <h2 className="text-[14px] mb-5 font-GothamMedium">Selected zone:</h2>
          <div className="flex flex-wrap gap-[10px]">
            {form.watch("zone") && form.watch("zone")!.length ? (
              <>
                {form.watch("zone")?.map((item: any, idx: number) => (
                  <div
                    key={`zone${idx}`}
                    className="flex flex-row gap-[5px] py-[6px] px-[10px] border-[1px] border-[#d6d6d6] rounded-[5px] bg-[#f4f4f4]"
                  >
                    <p className="text-[#000]">{item.label}</p>
                    <Button
                      onClick={() =>
                        form.setValue("zone", [...form.watch("zone")!.filter((zone: any) => zone.id !== item.id)])
                      }
                      type="button"
                      className="p-0 h-auto"
                      variant={"ghost"}
                    >
                      <img src={Close} height={12} width={12} alt="close" />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() => form.setValue("zone", [])}
                  variant={"ghost"}
                  className={"pl-[10px] !text-[#D06F1A] font-GothamBold  hover:!text-[#D06F1A] hover:underline  h-auto"}
                  type="button"
                >
                  Clear Selection
                </Button>
              </>
            ) : (
              <p className="leading-[34px]">No zone is selected</p>
            )}
          </div>
        </div>
      </div>
      <FormField
        control={form.control}
        name="zone"
        render={() => (
          <div className="flex flex-col gap-[10px]">
            <FormItem className="grid grid-cols-1 md:grid-cols-2 gap-x-[30px] gap-y-[10px]">
              {zone.slice(0, loadMore).map((item) => (
                <div className={`flex justify-between basis-full md:basis-1/2 !mt-0`} key={item.id}>
                  <FormField
                    control={form.control}
                    name="zone"
                    render={({ field }) => {
                      const isChecked = field.value && field.value?.some((val: any) => val.id == item.programZoneId);
                      return (
                        <FormItem
                          key={item.id}
                          aria-disabled={!item.isAvailable}
                          className={`flex flex-row zone-center basis-full border border-solid rounded-[10px] ${
                            isChecked ? "bg-[#f4f4f4]" : "bg-[#fff]"
                          } [&_span]:w-full [&_label]:w-full [&_label]:p-5  [&_label]:font-bold [&_label]:lg-1200:text-[16px]  [&_label]:mdtab:text-[12px] [&_label]:3xs:text-[16px] [&_label]:text-[12px] [&_label]:leading-[24px] ${item.isAvailable ? " [&_label]:cursor-pointer [&_label]:hover:text-[#d06f1a]" : "opacity-50"} [&_button]:tab-max-md:mb-[3px]`}
                        >
                          <FormLabel className="flex flex-row font-normal !mt-0 items-center">
                            <FormControl>
                              <Checkbox
                                checked={isChecked}
                                disabled={!item.isAvailable}
                                onCheckedChange={(checked) => {
                                  if (
                                    checked &&
                                    limit &&
                                    !isNaN(+limit) &&
                                    field.value &&
                                    field.value.length >= +limit
                                  ) {
                                    setAlert({
                                      message: `Maximum of ${limit} zones allowed.`,
                                      variant: "alert",
                                    });
                                    return;
                                  }
                                  return checked
                                    ? field.onChange([
                                        ...(field.value || []),
                                        { id: item.programZoneId, label: item.label },
                                      ])
                                    : field.onChange(field.value?.filter((val: any) => val.id !== item.programZoneId));
                                }}
                              />
                            </FormControl>
                            <div className="pt-[2px]">{item.label}</div>
                          </FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                </div>
              ))}
            </FormItem>
            <FormMessage className="error-msg !w-full [&>div]:ml-[15px] text-[12px]" />
          </div>
        )}
      />
      {zone?.length <= loadMore ? (
        <div className="text-center">
          <Button
            variant={"ghost"}
            type="button"
            onClick={() => setLoadMore(8)}
            className={"p-0 !text-[#D06F1A] font-GothamMedium mt-[-10px] hover:!text-[#D06F1A] hover:underline h-auto"}
          >
            Show less
          </Button>
        </div>
      ) : (
        <div className="text-center">
          <Button
            variant={"ghost"}
            type="button"
            onClick={() => setLoadMore((prev) => prev + 8)}
            className={"p-0 !text-[#D06F1A] font-GothamMedium mt-[-10px] hover:!text-[#D06F1A] hover:underline  h-auto"}
          >
            Load more zones
          </Button>
        </div>
      )}
    </div>
  );
}
