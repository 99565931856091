import React from "react";
import { Link } from "react-router-dom";
import LoginBg from "../assets/images/loginLeftBg.jpg";
import logo from "../assets/images/beerlogo.svg";

export default function AuthWrapper({ children }: React.PropsWithChildren) {
  return (
    <div className="flex flex-row">
      <div className="left-0 split shrink relative">
        <img src={LoginBg} alt={"login"} className="w-full h-full" />
        <div className="flex flex-col gap-[30px] text-center centered items-center">
          <img src={logo} alt={"logo"} width={"300px"} height={"52.456px"} />

          <p className="leading-[26px] text-[20px] text-white font-sans text-center">
            Welcome to our Advertising & Promotional Opportunities Brewer Portal.
          </p>
          <p className="leading-[26px] text-[20px] text-white font-sans text-center">
            For support please contact{" "}
            <Link className="text-[#D06F1A] hover:underline font-bold" to="mailto:brewerprograms@thebeerstore.ca">
              brewerprograms@thebeerstore.ca
            </Link>{" "}
          </p>
        </div>
        <div className="right-0 split shrink">{children}</div>
      </div>
    </div>
  );
}
