"use client";

import { FallbackProps, useErrorBoundary } from "react-error-boundary";
import { Button } from "src/components/ui/button";
import logo from "src/assets/images/full-logo.svg";
import Header from "src/pages/header";
import Footer from "src/pages/footer";

export default function Error({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <>
      <Header />
      <div className="flex justify-center bg-[#F4F4F4] h-[100vh] w-[100vw] pt-10">
        <div className="flex items-center justify-center flex-col gap-[20px] xs:gap-[40px] max-w-[700px] max-h-[428px] md:max-h-[375px] bg-white py-[20px] px-[15px] xs:py-[40px] xs:px-[30px] md:py-[60px] md:px-[60px] rounded-[10px] mx-[25px] h-inherit my-[0px]">
          <img src={logo} alt="Beer Store logo" className="h-[44px] w-[157px]" />
          <div className="flex items-center flex-col gap-[20px]">
            <h2 className="text-center  leading-normal xs:text-[30px] text-[#000] md:text-[32px] xs:leading-[42px] mb-[0px] text-[32px] font-GothamBold mt-[26px]">
              Something Went Wrong ....
            </h2>
            <Button
              className="rounded-full inline-block  h-auto text-[16px] font-GothamBold uppercase pt-[13px] pb-[11px] px-[30px] "
              onClick={() => resetErrorBoundary()}
            >
              Try Again
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
