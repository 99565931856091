import Step_1 from "src/assets/images/Step_1.svg";
import Step_2 from "src/assets/images/Step_2.svg";
import Step_3 from "src/assets/images/Step_3.svg";
import Step_4 from "src/assets/images/Step_4.svg";
import Step_5 from "src/assets/images/Step_5.svg";


export default function Steps(props: any) {
    const { className } = props;
    return (

        <div className={`${className} mainWrapper relative grid grid-row-2 lg-1100:grid-rows-none lg-1100:flex w-full m-auto rounded-[10px] justify-center overflow-hidden`}>

            <div className="flex lg-1100:basis-[60%]">
            <div className="infoBox flex flex-col items-center pr-[30px] w-full relative">
                <img
                    className="flex w-[48px] h-[48px] mb-5"
                    src={Step_1}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 1</h3>
                <p className="text-[14px] leading-[20px] text-center">Click Start Application from the area below or in the navigation bar</p>

                <div className="absolute top-[16%] left-[63%] transform translate-y-[-50%] w-[65%] mdtab:w-[70%] lg-1100:w-[65%] xl:w-[70%] h-0.5 border-t-2 border-dashed border-gray-300 flex">
                    <div className="w-2 h-2 bg-[#F4B233] rounded-full absolute transform translate-x-[-15%] top-[-5px]"></div>
                </div>
            </div>

            <div className="infoBox flex flex-col items-center pr-[30px] w-full relative">
                <img
                    className="flex w-[36px] h-[48px] mb-5"
                    src={Step_2}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 2</h3>
                <p className="text-[14px] leading-[20px] text-center">Enter details of your application</p>

                <div className="absolute top-[16%] left-[63%] transform translate-y-[-50%] w-[65%] mdtab:w-[70%] lg-1100:w-[65%] xl:w-[70%] h-0.5 border-t-2 border-dashed border-gray-300 flex">
                    <div className="w-2 h-2 bg-[#F4B233] rounded-full absolute transform translate-x-[-15%] top-[-5px]"></div>
                </div>
            </div>

            <div className="infoBox flex flex-col items-center pr-[30px] w-full relative">
                <img
                    className="flex w-[42px] h-[48px] mb-5"
                    src={Step_3}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 3</h3>
                <p className="text-[14px] leading-[20px] text-center">Attach one or more programs to the application</p>

                <div className="absolute top-[16%] left-[63%] transform translate-y-[-50%] w-[65%] mdtab:w-[70%] lg-1100:w-[65%] xl:w-[70%] h-0.5 lg-1100:border-t-2 border-dashed border-gray-300 flex">
                    <div className="w-2 h-2 lg-1100:bg-[#F4B233] rounded-full absolute transform translate-x-[-15%] top-[-5px]"></div>
                </div>
            </div>
            </div>

            <div className="flex lg-1100:basis-[40%] md:pt-[30px] lg-1100:pt-0">
            <div className="infoBox flex flex-col items-center pr-[30px] w-full relative">
                <img
                    className="flex w-[48px] h-[48px] mb-5"
                    src={Step_4}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 4</h3>
                <p className="text-[14px] leading-[20px] text-center">Submit the application</p>

                <div className="absolute top-[16%] left-[63%] transform translate-y-[-50%] w-[75%] lg-1100:w-[65%] xl:w-[70%] h-0.5 border-t-2 border-dashed border-gray-300 flex">
                    <div className="w-2 h-2 bg-[#F4B233] rounded-full absolute transform translate-x-[-15%] top-[-5px]"></div>
                </div>
            </div>

            <div className="infoBox flex flex-col items-center w-full">
                <img
                    className="flex w-[48px] h-[48px] mb-5"
                    src={Step_5}
                    alt={'Logo'}
                />
                <h3 className="text-[16px] leading-[24px] font-GothamBold mb-[10px]">Step 5</h3>
                <p className="text-[14px] leading-[20px] text-center">We will be in touch</p>
            </div>
            </div>
        </div>

    )
}
