import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

const alertVariants = cva(
  // "relative w-full rounded-lg border p-[15px] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground z-[99]",
  'fixed top-[10px] z-[900] flex max-h-screen w-auto flex-col-reverse p-4 sm:right-[10px] sm:flex-col md:max-w-[420px]',
  {
    variants: {
      variant: {
        default:
          "border-[1px] border-[#d6d6d6] rounded-[10px] bg-[#fff] text-[#000] dark:border-destructive [&>svg]:text-destructive",
        destructive:
          "border-[1px] border-[#CA6435] rounded-[10px] bg-[#F5C6CB] text-destructive dark:border-destructive [&>svg]:text-destructive",
        successful:
          "border-[1px] border-[#E0EADE] bg-[#F2F8F1] rounded-[10px] text-[#4A4F55] dark:border-destructive [&>svg]:text-destructive",
        alert:
          "border-[1px] border-[#bf1332] rounded-[10px] bg-[#f8d7da] text-[#ad2f33] dark:border-destructive [&>svg]:text-destructive",
        successfully:
          "border-[1px] border-[#E0EADE] bg-[#F2F8F1] rounded-[10px] min-w-[620px] text-[#4A4F55] dark:border-destructive [&>svg]:text-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = "Alert";

// const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
//   ({ className, ...props }, ref) => (
//     <h5 ref={ref} className={cn("mb-1 font-medium leading-none tracking-tight", className)} {...props} />
//   )
// );
// AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn("text-sm leading-[19px] max-w-[600px]", className)} {...props} />
  )
);
AlertDescription.displayName = "AlertDescription";

export { Alert, 
  // AlertTitle, 
  AlertDescription };
