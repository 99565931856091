import React, { useEffect } from "react";
import ThankYou from "../../components/ui/thankYou";
import Layout from "../layout/layout";
import { FullPageTemplate } from "src/modules/programsLayout/fullPageTemplate";
import PageRoutes from "src/constants/pageRoutes";
import { usePromotionStore } from "src/store/promotion";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {};

const breadcrumb = [
  {
    id: "1",
    key: "Home",
    value: PageRoutes.HOME,
  },
  {
    id: "2",
    key: "Thank You",
    value: PageRoutes.THANK_YOU,
  },
];

export default function ThankYouMessage({}: Props) {
  const { thankuId, setThankuId } = usePromotionStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const key = searchParams.get("key");

    if (!thankuId || !key || key != thankuId) {
      navigate(PageRoutes.HOME);
    } else {
      setThankuId();
    }
  }, []);

  return (
    <FullPageTemplate breadcrumbList={breadcrumb} className="py-0">
      <section className="container py-[0px] ">
        <ThankYou className="min-h-full  " />
      </section>
    </FullPageTemplate>
  );
}
