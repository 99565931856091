export default class PageRoutes {
  static readonly HOME = "/";
  static readonly LOGIN = "/login";
  static readonly REGISTER = "/register";
  static readonly PROGRAM = "/program";
  static readonly FORGOT_PASSWORD = "/forgot-password";
  static readonly THANK_YOU = "/thank-you";
  static readonly RESET_PASSWORD = "/reset-password";
  static readonly CONTACT_US = "/contact-us";
  static readonly PROMOTION = "/promotion";
  static readonly SUBMISSIONS = "/applications";
  static readonly TERMS_CONDITION = "/terms-condition";
  static readonly FAQ = "/faq";
  static readonly NOT_FOUND = "*";
  static programDetail(id: string | number) {
    return `${this.PROGRAM}/${id}`;
  }
  static promotionDetail(id: string | number) {
    return `${this.PROMOTION}/${id}`;
  }
}
