import React from "react";
import { ContainerLayout, ContentLayout } from "./index";
import BannerHeader from "./bannerHeader";
import Layout from "../../pages/layout/layout";
import Breadcrumb from "../breadcrumb";

interface FullPageTemplateProps {
  bannerTitle?: string | any;
  breadcrumbList?: [] | any;
  children?: React.ReactNode;
  bannerSubTitle?: string;
  desktopBg?: string;
  ipadBg?: string;
  mobileBg?: string;
  small?: boolean;
  mid?: boolean;
  className?: string;
  loading?: boolean;
}

export const FullPageTemplate: React.FC<FullPageTemplateProps> = ({
  bannerTitle,
  ipadBg,
  mobileBg,
  bannerSubTitle,
  desktopBg,
  children,
  small,
  mid,
  className = "",
  loading,
  breadcrumbList,
}) => {
  return (
    <Layout loading={loading}>
      {breadcrumbList && <div className="container pt-[20px] px-[24px] md:px-[30px] lg:px-[45px]">
        <Breadcrumb breadcrumbList={breadcrumbList} />
      </div>}
      {(bannerTitle || (mobileBg && desktopBg)) && (
        <BannerHeader
          title={bannerTitle}
          desktopBg={desktopBg}
          ipadBg={ipadBg}
          mobileBg={mobileBg}
          subtitle={bannerSubTitle}
          className={`${className}
            ${
              bannerTitle || desktopBg || ipadBg || mobileBg || bannerSubTitle
                ? small
                  ? "!min-h-[200px]"
                  : mid
                    ? "!min-h-[250px] md:!min-h-[200px]"
                    : ""
                : "hidden"
            }
          `}
        />
      )}

      <ContainerLayout className="container py-[80px]">
        <ContentLayout className="basis-full">{children}</ContentLayout>
      </ContainerLayout>
    </Layout>
  );
};
