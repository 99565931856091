import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./modules/error";
import api from "./services/api";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "./config";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ErrorBoundary
        fallbackRender={Error}
        onError={(error) =>
          api.miscellaneous.postLogs({
            url: "brewer-portal/global-error",
            data: { error, path: window.location.pathname },
          })
        }
      >
        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey!}>
          <App />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
