import { FullPageTemplate } from "../../modules/programsLayout/fullPageTemplate";
import { Button } from "../../components/ui/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Badge } from "../../components/ui/badge";
import PromotionalForm from "../../components/promotionalForm";
import { useEffect, useMemo, useRef, useState } from "react";
import DeleteIcon from "../../assets/images/DeleteIcon.svg";
import PageRoutes from "src/constants/pageRoutes";
import { useAlertStore } from "src/store/alert";
import { usePromotionStore } from "src/store/promotion";
import api from "src/services/api";
import { downloadFileByUrl, exceptionHandler } from "src/utils/helper";
import { ErrorMessages } from "src/constants/errorMessages";
import RemoveProgramConfirmationModal from "src/modules/modal/saveChangesConfirmationModal";
import { REMOVE_PROGRAM_CONFIRMATION_TEXT } from "src/constants";
import DisclaimerText from "src/components/disclaimerText";
import { IPromotion } from "src/types/promotion.type";
import goBack from "src/assets/images/goBack.svg";
import ProgramStatusBadge from "src/modules/programStatusBadge";
import { useGlobalLoader } from "src/store/loader";

interface RemoveProgramModalProps {
  toggleOpen: (url?: string | number) => void;
}

function PromotionalDetails() {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlertStore();
  const { loading: gLoading } = useGlobalLoader();
  const { id: promotionId } = useParams();
  const { promotion: gPromotion, setPromotion: gSetPromotion, resetPromotion, setThankuId } = usePromotionStore();
  const removeConfirmationModalRef = useRef<RemoveProgramModalProps>();
  const [cPromotion, cSetPromotion] = useState<IPromotion>();
  const promotion = useMemo(() => cPromotion || gPromotion, [cPromotion, gPromotion]);
  const isPending = promotion?.status === "DRAFT";

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload: any = {
        promotionId: promotion!.id,
        programIds: promotion!.program?.map((item) => item.id),
      };
      const res = await api.promotion.submit(payload);
      if (res.status === 200 || res.status === 201) {
        const thankuId = Math.floor(Math.random() * 100000);
        setThankuId(thankuId);
        navigate(PageRoutes.THANK_YOU + `?key=${thankuId}`);

        setTimeout(() => {
          resetPromotion();
        }, 100);
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (promotionId != gPromotion?.id) {
      (async () => {
        try {
          setLoading(true);
          const res = await api.promotion.getById(promotionId!);
          if (res.status === 200) {
            if (res.data?.data?.length) {
              cSetPromotion(res.data.data[0]);
            }
          } else {
            throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
          }
        } catch (error) {
          exceptionHandler(error, setAlert, "destructive");
        } finally {
          setLoading(false);
        }
      })();
    } else if (promotionId == gPromotion.id) {
      setLoading(false);
      cSetPromotion(undefined);
    }
  }, [promotionId]);

  useEffect(() => {
    if (!loading && !gLoading && !cPromotion?.id && (!gPromotion.id || gPromotion?.id != promotionId)) {
      navigate(PageRoutes.HOME);
    }
  }, [loading, gLoading, cPromotion, gPromotion]);

  async function onSaveHandler(id: number) {
    removeConfirmationModalRef.current?.toggleOpen();
    await deleteByIdHandler(id);
  }
  function onCancelHandler() {
    removeConfirmationModalRef.current?.toggleOpen();
  }

  const deleteByIdHandler = async (id: number) => {
    try {
      setLoading(true);
      const res = await api.program.delete(id.toString());
      if (res.status == 200) {
        const program = promotion?.program?.filter((item) => item.id !== id) || [];
        gSetPromotion({ program });
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setLoading(false);
    }
  };
  const breadcrumb = [
    {
      id: "1",
      key: "Home",
      value: PageRoutes.HOME,
    },
  ].concat(
    isPending
      ? [
          {
            id: "2",
            key: promotion?.brandName,
            value: PageRoutes.promotionDetail(promotionId!),
          },
        ]
      : [
          {
            id: "2",
            key: "All Applications",
            value: PageRoutes.SUBMISSIONS,
          },
          {
            id: "3",
            key: "Promotional Details",
            value: PageRoutes.promotionDetail(promotionId!),
          },
        ]
  );

  return (
    <FullPageTemplate loading={loading} breadcrumbList={breadcrumb}>
      <div className="max-w-[1090px] m-auto">
        {isPending ? (
          <DisclaimerText content="Update your application by following the prompts OR submit your application for review by the Brewer Programs Team." />
        ) : (
          <Link className="flex flex-row gap-[10px] w-fit mb-[40px] " to={PageRoutes.SUBMISSIONS}>
            <img src={goBack} width={16} height={14} alt="Back" style={{ cursor: "pointer", marginRight: 10 }} />
            <span className="text-[14x] leading-[20px] font-GothamBold text-[#D06F1A] hover:underline ">
              {" "}
              Back to All Application
            </span>
          </Link>
        )}
      </div>
      <div className="max-w-[1090px] m-auto py-[40px] border-[1px] border-solid rounded-[10px] border-[#d6d6d6] 3xs:px-[20px] xs:px-[50px] md:px-[90px] mdtab:px-[140px]">
        {!isEdit ? (
          <div className="flex flex-col m-auto text-black">
            <h3 className="text-[24px] leading-[34px] font-GothamBold pb-[30px]">Promotional Details</h3>
            <div className="flex flex-col gap-[10px]">
              <h4 className="text-[16px] font-GothamBold leading-[24px] ">{promotion?.brandName}</h4>
              <div className="flex flex-row gap-[60px] text-[#4A4F55] [&_p]:text-[14px] [&_p]:leading-[20px] [&_p]:font-normal">
                <div>
                  <p>
                    {"Pack Size: "}
                    {promotion?.packSize || ""}
                  </p>
                  {/* <p>
                    {"Volume: "}
                    {promotion?.volume || ""}
                  </p> */}
                  <p>
                    {"Container: "}
                    {promotion?.container}
                  </p>
                  <p>
                    {"Article #: "}
                    {promotion?.articleNumber || ""}
                  </p>
                </div>
                <div>
                  <p>
                    {"SKU Price: $"}
                    {promotion?.skuPricing || ""}
                  </p>
                  {/* <p>
                    {"On Sale: "}
                    {promotion?.offers ? "Yes" : "No"}
                  </p> */}
                  <p>
                    {"Offer: "}
                    {promotion?.offers || ""}
                  </p>
                  {/* <p>
                    {"Promo: "}
                    {promotion?.promoCase || "No"}
                  </p> */}
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-[20px] gap-[10px]">
              <h4 className="text-[14px] font-GothamBold text-black">Advertising Summary</h4>
              <p className="text-[14px] font-[325] text-[#4A4F55] pb-[30px]">{promotion?.advertisingSummary || ""}</p>
            </div>

            {isPending && (
              <div className="flex py-[10px]">
                <Button
                  variant="link"
                  onClick={() => setIsEdit(true)}
                  className="text-[#D06F1A] font-GothamBold hover:underline px-0 py-0 h-[20px] items-baseline"
                >
                  Edit promotional details
                </Button>
              </div>
            )}
          </div>
        ) : (
          <PromotionalForm closeEdit={() => setIsEdit(false)} promotion={promotion!} editForm={true} />
        )}

        <div className="flex flex-col gap-[20px] m-auto pt-[40px] border-solid border-t-[1px] text-black">
          <h3 className="text-[24px] leading-[34px] font-GothamBold">Program Details</h3>
          {promotion?.program?.length ? (
            promotion?.program?.map((item, index) => (
              <div key={item.id} className="flex flex-row gap-[30px] border-b-[1px] border-[#D6D6D6] pb-[20px] ">
                <div className="flex-none w-[183px] h-[125px]">
                  <img
                    src={item?.image}
                    alt="green-check"
                    className="w-[100%] h-[100%] group-data-[state=inactive]:hidden"
                  />
                </div>

                <div className="flex flex-col grow gap-[10px]">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-[20px]">
                    <p className="gap-[10px] lg-1100:flex-row items-start lg-1100:items-center text-[18px] leading-[22px] font-GothamBold text-black">
                      {item?.name}
                    </p>
                    <Badge
                        variant={"outline"}
                        className="min-w-[78px] min-h-[26px] border-[#4A4F55] uppercase text-[11px] leading-[15px] lg:text-[14px] lg:leading-[18px] px-[12px] pt-[6px] pb-[6px] mr-[20px] font-GothamMedium justify-center bg-[#4A4F55] text-white text-center"
                      >
                        {item.category}
                      </Badge>
                    </div>

                    {isPending && (
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        className="w-[17.5px] h-[20px] cursor-pointer"
                        onClick={() => removeConfirmationModalRef.current?.toggleOpen(item.id)}
                      />
                    )}
                  </div>

                  <div>
                    <p className="text-[14px] text-[#4A4F55]">Selected Cycle: {item?.cycle.value}</p>
                    {Array.isArray(item?.promotionZoneStore) && item.programType == "ZONE" ? (
                      <p className="text-[14px] text-[#4A4F55]">
                        Selected Zone: {item?.promotionZoneStore.map((item) => item.value).join(", ")}
                      </p>
                    ) : null}
                    {item?.promotionZoneStore?.length && item.programType == "STORE_LIST" ? (
                      <p className="text-[14px] text-[#4A4F55]">
                        Selected Stores:{" "}
                        <span
                          onClick={() =>
                            downloadFileByUrl(
                              (item?.promotionZoneStore?.length && item?.promotionZoneStore[0]?.storeExcel) || ""
                            )
                          }
                          className="cursor-pointer text-[#4A4F55] hover:underline text-[14px] font-GothamBold"
                        >
                          Download List
                        </span>
                      </p>
                    ) : null}
                  </div>
                  {isPending ? (
                    <div>
                      <span
                        onClick={() => navigate(PageRoutes.programDetail(item?.programId))}
                        className="text-[#D06F1A] hover:underline cursor-pointer font-GothamBold text-[14px]"
                      >
                        Edit booking details
                      </span>
                    </div>
                  ) : (
                    // <div>{item.status}</div>

                    <div>
                      <ProgramStatusBadge status={item.status} />
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div>
              <p>No Program found!</p>
            </div>
          )}
        </div>

        {isPending && (
          <div className="flex flex-col sm:flex-row px-[0] pt-[40px] gap-[30px] justify-end">
            <Button
              type="button"
              onClick={() => navigate(PageRoutes.PROGRAM)}
              className="uppercase h-[50px] md:w-[233 px] text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px] bg-black text-white border-black boder-[1px] items-center"
            >
              Add More Programs
            </Button>
            <Button
              type="button"
              onClick={onSubmit}
              className="uppercase h-[50px] text-[14px] font-GothamBold leading-5 rounded-[200px] px-[30px] py-[15px] items-center"
              disabled={!promotion?.program || promotion.program?.length < 1}
            >
              Submit Application
            </Button>
          </div>
        )}
      </div>
      <RemoveProgramConfirmationModal
        title={REMOVE_PROGRAM_CONFIRMATION_TEXT}
        ref={removeConfirmationModalRef}
        onCancel={onCancelHandler}
        onSubmit={onSaveHandler}
      />
    </FullPageTemplate>
  );
}

export default PromotionalDetails;
