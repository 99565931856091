import React, { useState } from "react";

export default function EqualHeight(classOne: string, classTwo: string, loading?: boolean) {
  const [currentTallest, setCurrentTallest] = useState<number>(0);

  const equalHeight = (containerSelector: string, articlesPerRow: number) => {
    const containers = document.querySelectorAll<HTMLDivElement>(containerSelector);

    containers.forEach((container) => {
      container.style.height = "auto";
    });

    let currentRowStart = 0;
    let rowDivs: HTMLDivElement[] = [];
    let topPosition = 0;

    containers.forEach((container, index) => {
      const el = container;
      el.style.height = "auto";
      topPosition = el.offsetTop;

      if (currentRowStart !== topPosition) {
        setEqualHeight(rowDivs);

        rowDivs = [];
        currentRowStart = topPosition;
      }

      rowDivs.push(el);

      // Check if this is the last article in the row or the last article overall
      if ((index + 1) % articlesPerRow === 0 || index === containers.length - 1) {
        setEqualHeight(rowDivs);
      }
    });
  };

  const setEqualHeight = async (elements: HTMLDivElement[]) => {
    const headings: HTMLHeadingElement[] = [];
    const paragraphs: HTMLParagraphElement[] = [];
    const div: any = [];
    const span: HTMLSpanElement[] = [];
    const devTwo: any = [];
    elements.forEach((el) => {
      const heading = el.querySelector("h2");
      if (heading) {
        headings.push(heading);
      }
      const devEle = el.querySelector(".recipes-height");

      if (devEle) {
        div.push(devEle);
      }
      const devEleTwo = el.querySelector(".recipes-heightTwo");

      if (devEleTwo) {
        devTwo.push(devEleTwo);
      }

      const spanEle = el.querySelector("span");

      if (spanEle) {
        span.push(spanEle);
      }

      const paragraph = el.querySelector("p");
      if (paragraph) {
        paragraphs.push(paragraph);
      }
    });

    const maxHeadingHeight = Math.max(...headings.map((h) => h.offsetHeight));

    headings.forEach((h) => {
      h.style.height = `${maxHeadingHeight}px`;
    });
    ////////
    const maxFDivHeight = Math.max(...div.map((h: any) => h.offsetHeight));

    div.forEach((h: any) => {
      h.style.height = `${maxFDivHeight}px`;
    });
    const devTwoHeight = Math.max(...devTwo.map((h: any) => h.offsetHeight));

    devTwo.forEach((h: any) => {
      h.style.height = `${devTwoHeight}px`;
    });

    ////////////
    const maxSpanHeight = Math.max(...span.map((h) => h.offsetHeight));

    span.forEach((h) => {
      h.style.height = `${maxSpanHeight}px`;
    });
    ////////////
    const maxParagraphHeight = Math.max(...paragraphs.map((p) => p.offsetHeight));

    paragraphs.forEach((p) => {
      p.style.height = `${maxParagraphHeight}px`;
    });

    setCurrentTallest(Math.max(maxHeadingHeight, maxParagraphHeight));
  };

  React.useEffect(() => {
    setTimeout(() => {
      equalHeight(`.${classOne} .${classTwo}`, 1);
    });
  }, [loading]);

  React.useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        equalHeight(`.${classOne} .${classTwo}`, 1);
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [loading]);
}
