import Header from "../header";
import Footer from "../footer";
import MugLoader from "src/components/ui/mugLoader";
import { useAuth } from "src/context/authContext";
import { useEffect } from "react";
import { useGlobalLoader } from "src/store/loader";

export default function Layout({ children, loading }: { children?: React.ReactNode; loading?: boolean }) {
  const { loading: authLoader } = useAuth();
  const { loading: globalLoader } = useGlobalLoader();
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  return (
    <>
      <Header />
      <div
        className={`overflow-y-auto relative ${(loading || globalLoader || authLoader) && "overflow-hidden"} `}
        style={{ minHeight: "calc(100vh - 190px)" }}
      >
        {(loading || globalLoader || authLoader) && <MugLoader />}
        {children}
      </div>
      <Footer />
    </>
  );
}
