import * as React from "react";

import { cn } from "../../lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon, endIcon, "aria-describedby": ariaDescribedby, ...props }, ref) => {
    return (
      <div
        className={cn(
          "flex w-full items-center rounded-[30px] border border-input bg-background px-0 py-0 text-sm ring-offset-background  disabled:cursor-not-allowed disabled:opacity-50 overflow-hidden relative",
          className
        )}
      >
        {startIcon}
        <input
          className="flex flex-1 file:border-0 bg-[#fff] file:bg-transparent file:text-sm file:font-medium  placeholder:text-[#4A4F55]  focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 py-[14px] px-[20px]"
          type={type}
          ref={ref}
          {...props}
        />
        <div className="absolute right-[20px]">{endIcon}</div>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
