import { Outlet, Navigate } from "react-router-dom";
import PageRoutes from "src/constants/pageRoutes";
import { useAuth } from "src/context/authContext";

const IsProtectedRoute = ({ is }: { is: boolean }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? ( // token existed
    is ? (
      <Outlet />
    ) : (
      <Navigate to={PageRoutes.HOME} />
    )
  ) : !is ? (
    <Outlet />
  ) : (
    <Navigate to={PageRoutes.LOGIN} />
  );
};

export default IsProtectedRoute;
