import { Button } from "src/components/ui/button";

interface NoResultsProps {
  clearFilters: () => void;
}
export default function NoResults({ clearFilters }: NoResultsProps) {
  return (
    <div className="w-full h-[200px] flex items-center justify-center flex-col">
      <h2 className="w-[100%] text-center mb-0px size-8 font-semibold">No result found!</h2>
      <Button
        className="h-auto font-GothamBold text-[12px] xs:text-[16px] py-[15px] px-[30px] rounded-[200px] uppercase leading-[1.0] mt-[25px] max-[50px]"
        asChild
      >
        <Button onClick={clearFilters} className="uppercase">
          Discover More
        </Button>
      </Button>
    </div>
  );
}
