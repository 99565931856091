import { ErrorMessages } from "src/constants/errorMessages";
import api from "src/services/api";
import { refreshTokenHandler } from "./cookieHelper";
export const exceptionHandler = (
  error: any,
  callback: any,
  alert?: "destructive" | "successful" | "alert" | "default"
): void => {
  console.error(error);
  if (error?.response && error?.response?.status) {
    alert &&
      callback({
        isOpen: true,
        variant: "destructive",
        message:
          (error?.response?.data?.message &&
            typeof error?.response?.data?.message == "string" &&
            error?.response?.data?.message) ||
          ErrorMessages.SOMETHING_WENT_WRONG,
      });
  } else if (error?.message) {
    alert &&
      callback({
        isOpen: true,
        variant: "destructive",
        message: error?.message || ErrorMessages.SOMETHING_WENT_WRONG,
      });
  }
};

export const formatPhoneNumber = (value: string) => {
  let cleanedValue = value.replace('+1', '');
  cleanedValue = cleanedValue.replace('1(', '');
  cleanedValue = cleanedValue.replace(/\D/g, '').slice(0, 10); // Limit to 10 digits
  let formattedValue = cleanedValue.replace(
    /(\d{0,3})(\d{0,3})(\d{0,4})/,
    function (match, p1, p2, p3) {
      return '+1' + (!p2 ? p1 : '(' + p1 + ') ') + p2 + (p3 ? '-' + p3 : '');
    },
  );
  return formattedValue;
};

export const downloadFileByUrl = (fileUrl: string, fileName: string = "stores.csv", target: string = "_self") => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const refreshToken = async (retriesCount: number = 0): Promise<any> => {
  try {
    const res = await api.miscellaneous.refreshToken({
      refreshToken: refreshTokenHandler("GET"),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error: any) {
    if (error?.response && retriesCount > 0) {
      return refreshToken(retriesCount - 1);
    }
  }
};
